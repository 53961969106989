import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from '../../containers/AlternativeNavbar/Navbar';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { IconContext } from 'react-icons/lib';
import { FaExpandAlt } from 'react-icons/fa';
import { FaScrewdriver } from 'react-icons/fa';
import { ImPriceTag } from 'react-icons/im';
import { MdDone } from 'react-icons/md';
import { withStyles } from '@material-ui/core/styles';
import { resetState } from '../../services/products/product/reducer';
import { resetStep } from '../../services/products/product/stepReducer';
import { changeCookieShow } from '../../services/users/userReducer';
import { useSelector, useDispatch } from 'react-redux';
import { changeStep } from '../../services/products/product/stepReducer';
import classes from './PageWrapper.module.css';
import AddressPage from '../AddressPage/AddressPage';
import DrawingPage from '../DrawingPage/DrawingPage';
import RoofSlopePage from '../RoofSlopePage/RoofSlopePage';
import ContactInfoComp from '../SummaryPage/ContactInfoComp';
// import InvoiceInfoPage from '../InvoiceInfoPage/InvoiceInfoPage';

const StyledAlert = withStyles({
  root: {
    backgroundColor: '#373242'
  }
})(MuiAlert);

function Alert(props) {
    return <StyledAlert elevation={6} variant="filled" {...props} />;
  }

  export default function PageWrapper(props) {
    const { t, } = useTranslation();
    const dispatch = useDispatch();
    const { cookieShow } = useSelector(state => state.users);
    const { stepNumber, lastInfo, quoteSend } = useSelector(state => state.steps);
    const { erisim, kuvvet, fiyat } = useSelector(state => state.summary);
    const { module } = useSelector(state => state.module);
    const [cookieShowState, setCookieShow] = React.useState(!cookieShow);

    const handleChange = (newValue) => {
      dispatch(changeStep({stepNumber: newValue}));
    };
  
    const willMount = useRef(true);
    if (willMount.current) {
      // const dataGet = axiosGet();
      willMount.current = false;
      dispatch(resetState());
      dispatch(resetStep());

      /* 
      Eski format -> Önce özet ekranı sonra kontak bilgileri ekranı çıksın diye

      if (module === "solarvis") {
        dispatch(changeQuote({ quoteSend: true }));
      } 
      */
    }
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setCookieShow(false);
      dispatch(changeCookieShow({ cookieShow: true }))
    };
    
    return (
      <>
        <main className={classes.mainWrapper}>
          <Navbar/>
          {quoteSend && <div 
          style={{
            height: '100vh', 
            width:'100%', 
            backgroundColor: 'whitesmoke',
            display:'flex',
            flexDirection: 'column',
            textAlign:'center',
            placeContent: 'center',
            placeItems: 'center'
            }}>
            <div className={classes.circleDiv}>
              <IconContext.Provider value={{ color: '#228f26', size: 150 }}>
                <MdDone />
              </IconContext.Provider>
            </div>
            <div>
              <p style={{fontSize: '20px'}}>
                {t('SistemPage.sentDesc')}
              </p>
              <div>
                <a
                  href="http://integva.com/">
                  <button className={classes.HomeButton}>{t('SistemPage.homepage')}</button>
                </a>
              </div>
            </div>
          </div>}
          <h1 className={classes.headerOne}>{t('ProgressBar.header')}</h1>
          {!quoteSend &&
          <div className={classes.outerWrapper}>
              <div className={classes.wrapper}>
                  <div className={classes.solarCalculator}>
                    <div className={classes.anotherWrapper}>
                      <div className={classes.motionDiv}>
                        <div className={stepNumber !== 5 ? classes.stepWrapper : classes.stepWrapperLast }>
                          {stepNumber !== 5 &&
                          <div className={classes.stepper}>
                            <div className={classes.leftStepper}>
                              <button index={0} onClick={() => handleChange(0)} className={classes.stepperButton}><span className={stepNumber === 0 ? classes.buttonTextUnderline : classes.buttonText }>{t('ProgressBar.sistem')}</span></button>
                              <button index={1} onClick={() => handleChange(1)} className={classes.stepperButton}><span className={stepNumber === 1 ? classes.buttonTextUnderline : classes.buttonText}>{t('ProgressBar.konfigürasyon')}</span></button>
                              <button disabled={(fiyat === '-')} index={2} onClick={() => handleChange(2)} className={classes.stepperButton}><span className={stepNumber === 2 ? classes.buttonTextUnderline : classes.buttonText}>{t('ProgressBar.liste')}</span></button>
                              <button disabled={(fiyat === '-')} index={3} onClick={() => handleChange(3)} className={classes.stepperButton}><span className={stepNumber === 3 ? classes.buttonTextUnderline : classes.buttonText}>{t('ProgressBar.buy')}</span></button>
                            </div>
                            <div className={classes.rightStepper}>
                              <div className={classes.costSavingWrapper}>
                                <IconContext.Provider value={{ color: '#ffffff', size: 30 }}>
                                  <FaExpandAlt />
                                </IconContext.Provider>
                                <div className={classes.costSaving}>
                                  <span className={classes.costSavingText}>{erisim} mm</span>
                                  <span className={classes.costSavingText2}>{t('ProgressBar.erisim')}</span>
                                </div>
                              </div>
                              <div className={classes.totalCost}>
                                <IconContext.Provider value={{ color: '#ffffff', size: 24 }}>
                                  <FaScrewdriver />
                                </IconContext.Provider>
                                <div className={classes.costSaving}>
                                  <span className={classes.costSavingText}>{kuvvet} kg</span>
                                  <span className={classes.costSavingText2}>{t('ProgressBar.kuvvet')}</span>
                                </div>
                              </div>
                              <div className={classes.totalCost}>
                                <IconContext.Provider value={{ color: '#ffffff', size: 24 }}>
                                  <ImPriceTag />
                                </IconContext.Provider>
                                <div className={classes.costSaving}>
                                  <span className={classes.costSavingText}>{fiyat} €</span>
                                  <span className={classes.costSavingText2}>{t('ProgressBar.fiyat')}</span>
                                </div>
                              </div>
                            </div>
                          </div>}
                          {stepNumber === 0 && <AddressPage />}
                          {stepNumber === 1 && <DrawingPage />}
                          {stepNumber === 2 && <RoofSlopePage />}
                          {stepNumber === 3 && <ContactInfoComp />}
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
          }
          <Snackbar open={cookieShowState} /* autoHideDuration={6000} */ anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} onClose={handleClose}>
            <Alert onClose={handleClose} severity="info">
              {t('KVKK.kvkk1')} 
              <br/>
              {t('KVKK.kvkk2')} 
              <br/>
              {t('KVKK.kvkk3')}
            </Alert>
          </Snackbar>
        </main>
        
    </>
    )
  }