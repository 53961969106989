import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../components/axios/axiosApi';
import classes from './SummaryPage.module.css';
import { IconContext } from 'react-icons/lib';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';
import { changeStep, changeQuote } from '../../services/products/product/stepReducer';
import { changeIsim, changeSoyisim, changeTelefon, changeMail, changeSirket } from '../../services/users/userReducer';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

function ContactInfoComp(props) {

    const dispatch = useDispatch();
    const { t, } = useTranslation();

    const {
      erisim,
      kuvvet,
      fiyat,
      listeFiyatı
    } = useSelector(state => state.summary);

    const {
      sistem,
      eksen,
      eklem1,
      eklem2,
      eklem3,
      eklem4,
      eklem5,
      eklem6,
    } = useSelector(state => state.module);

    const {
      checkoutCoint20,
      checkoutCoint50,
      checkoutCoint100,
      checkoutAyak20,
      checkoutAyak50,
      checkoutAyak100,
      checkoutBoru200,
      checkoutBoru400,
      checkoutBoru600,
      checkoutTabanSacı1,
      checkoutTabanSacı2,
      checkoutDeltaKol1,
      checkoutDeltaKol2,
      checkoutDeltaPanel1,
      checkoutDeltaPanel2,
      checkoutTabla1,
      checkoutTabla2,
      checkoutKasa20,
      checkoutKasa50,
      checkoutKasa100,
      checkoutScaraKol1,
      checkoutScaraKol2,
      checkoutPano1,
      checkoutPano2,
      checkoutPano3,
    } = useSelector(state => state.checkout);

    const {
      coint20,
      coint50,
      coint100,
      ayak20,
      ayak50,
      ayak100,
      boru200,
      boru400,
      boru600,
      tabanSacı1,
      tabanSacı2,
      deltaKol1,
      deltaKol2,
      deltaPanel1,
      deltaPanel2,
      tabla1,
      tabla2,
      kasa20,
      kasa50,
      kasa100,
      scaraListKol1,
      scaraListKol2,
      pano1,
      pano2,
      pano3,
    } = useSelector(state => state.list);

    const {
      cobotAyak,
      cobotBoru1,
      cobotBoru2,
      cobotPano,
      cobotKasa2,
      cobotKasa3,
      cobotKasa4,
      cobotKasa5,
      cobotKasa6
    } = useSelector(state => state.cobot);

    const {
      scaraKol1,
      scaraKol2,
      scaraAyak,
      scaraPano,
    } = useSelector(state => state.scara);

    const {
      tabla,
      tablaAyak,
      tablaPano,
      tablaKasa
    } = useSelector(state => state.tabla);

    const {
      deltaKol,
      deltaSac,
      deltaPanel,
      deltaPano,
      deltaKasa1,
      deltaKasa2,
      deltaKasa3
    } = useSelector(state => state.delta);

    function repeatStringNumTimes(string, times) {
        if (times > 0) {
            string = (String(string) + '|').repeat(times);
            string = string.slice(0, -1);
            return string;
        } else {
            return "";
        }
    }
      // repeatStringNumTimes("abc", 3);

    const removeDuplicates = (s) => {
 
        let n = s.length;
        let str = "";
        // We don't need to do anything for
        // empty string.
        if (n == 0)
            return str;
 
        // Traversing string
        for (let i = 0; i < n - 1; i++) {
            //checking if s[i] is not same as s[i+1] then add it into str
            if (s[i] == '|') {
                if (s[i] != s[i + 1]) {
                    str += s[i];
                }   
            } else {
                str += s[i];
            }
        }
        //Since the last character will not be inserted in the loop we add it at the end
         
        str += s[n-1];
        if (str.substring(0,1) == '|') {
            str = str.slice(1,);
        }
        if (str.substring(str.length - 1) == '|') {
            str = str.slice(0,-1);
        }
        return str;
    }

    const submitHandler = ( event ) => {

        event.preventDefault();

        /* dispatch(changeQuote({ quoteSend: true }));
        dispatch(changeIsim({ isim: contactInfo.name }));
        dispatch(changeSoyisim({ soyisim: contactInfo.surname }));
        dispatch(changeTelefon({ telefon: contactInfo.phoneNumber }));
        dispatch(changeMail({ mail: contactInfo.email }));
        dispatch(changeQuote({ quoteSend: true })); */

        let sistemTipi = ''; //cok_eksenli, doner_tabla, delta_robot veya scara_robot olabilir.
        if (sistem === "tabla") {
            sistemTipi = "doner_tabla";
            let eksenListesi = '';
            let tablaKasaX;
            if (eksen === '1') {
                eksenListesi = eklem1;
                tablaKasaX = ''; 
            } else if (eksen === '2') {
                eksenListesi = `${eklem1}|${eklem2}`;
                tablaKasaX = tablaKasa; 
            }
            
            axiosInstance.get(`/save_customer?iletisim=[${contactInfo.name}|${contactInfo.surname}|${contactInfo.phoneNumber}|${contactInfo.email}|${contactInfo.company}]&sistem_tipi=${sistemTipi}&ls_coint=${removeDuplicates(repeatStringNumTimes('20', checkoutCoint20)+'|'+repeatStringNumTimes('50', checkoutCoint50)+'|'+repeatStringNumTimes('100', checkoutCoint100))}&ls_tabla=${removeDuplicates(repeatStringNumTimes('1', checkoutTabla1)+'|'+repeatStringNumTimes('2', checkoutTabla2))}&ls_kol=&ls_sac=&ls_ayak=${removeDuplicates(repeatStringNumTimes('20', checkoutAyak20)+'|'+repeatStringNumTimes('50', checkoutAyak50)+'|'+repeatStringNumTimes('100', checkoutAyak100))}&ls_boru=&ls_kasa=${removeDuplicates(repeatStringNumTimes('20', checkoutKasa20)+'|'+repeatStringNumTimes('50', checkoutKasa50)+'|'+repeatStringNumTimes('100', checkoutKasa100))}&ls_panel=&ls_pano=${repeatStringNumTimes('1', checkoutPano1)}&ls_fiyat=${listeFiyatı}0&uc_kuvveti=${kuvvet}&erisim_alani=${erisim}&cfg_coint=${eksenListesi}&cfg_tabla=${tabla}&cfg_kol=&cfg_sac=&cfg_ayak=${tablaAyak}&cfg_boru=&cfg_kasa=${tablaKasaX}&cfg_panel=&cfg_pano=1&cfg_fiyat=${fiyat}&format=json`)
                .then(response => {
                    //console.log(response.data)
                    // dispatch(changeStep({stepNumber: 5}));
                    dispatch(changeQuote({ quoteSend: true }));
                })
                .catch( error => {
                    console.log(error);
                })

        } else if (sistem === "scara") {
            sistemTipi = "scara_robot";
            axiosInstance.get(`/save_customer?iletisim=[${contactInfo.name}|${contactInfo.surname}|${contactInfo.phoneNumber}|${contactInfo.email}|${contactInfo.company}]&sistem_tipi=${sistemTipi}&ls_coint=${removeDuplicates(repeatStringNumTimes('20', checkoutCoint20)+'|'+repeatStringNumTimes('50', checkoutCoint50)+'|'+repeatStringNumTimes('100', checkoutCoint100))}&ls_tabla=&ls_kol=${removeDuplicates(repeatStringNumTimes('1', checkoutScaraKol1)+'|'+repeatStringNumTimes('2', checkoutScaraKol2))}&ls_sac=&ls_ayak=${removeDuplicates(repeatStringNumTimes('20', checkoutAyak20)+'|'+repeatStringNumTimes('50', checkoutAyak50)+'|'+repeatStringNumTimes('100', checkoutAyak100))}&ls_boru=&ls_kasa=&ls_panel=&ls_pano=${repeatStringNumTimes('2', checkoutPano2)}&ls_fiyat=${listeFiyatı}0&uc_kuvveti=${kuvvet}&erisim_alani=${erisim}&cfg_coint=${eklem1}|${eklem2}&cfg_tabla=&cfg_kol=${scaraKol1}|${scaraKol2}&cfg_sac=&cfg_ayak=${scaraAyak}&cfg_boru=&cfg_kasa=&cfg_panel=&cfg_pano=2&cfg_fiyat=${fiyat}&format=json`)
                .then(response => {
                    //console.log(response.data)
                    // dispatch(changeStep({stepNumber: 5}));
                    dispatch(changeQuote({ quoteSend: true }));
                })
                .catch( error => {
                    console.log(error);
                })

        } else if (sistem === "delta") {
            sistemTipi = "delta_robot";
            axiosInstance.get(`/save_customer?iletisim=[${contactInfo.name}|${contactInfo.surname}|${contactInfo.phoneNumber}|${contactInfo.email}|${contactInfo.company}]&sistem_tipi=${sistemTipi}&ls_coint=${removeDuplicates(repeatStringNumTimes('20', checkoutCoint20)+'|'+repeatStringNumTimes('50', checkoutCoint50)+'|'+repeatStringNumTimes('100', checkoutCoint100))}&ls_tabla=&ls_kol=${removeDuplicates(repeatStringNumTimes('1', checkoutDeltaKol1)+'|'+repeatStringNumTimes('2', checkoutDeltaKol2))}&ls_sac=${removeDuplicates(repeatStringNumTimes('1', checkoutTabanSacı1)+'|'+repeatStringNumTimes('2', checkoutTabanSacı2))}&ls_ayak=&ls_boru=&ls_kasa=${removeDuplicates(repeatStringNumTimes('20', checkoutKasa20)+'|'+repeatStringNumTimes('50', checkoutKasa50)+'|'+repeatStringNumTimes('100', checkoutKasa100))}&ls_panel=${removeDuplicates(repeatStringNumTimes('1', checkoutDeltaPanel1)+'|'+repeatStringNumTimes('2', checkoutDeltaPanel2))}&ls_pano=${repeatStringNumTimes('2', checkoutPano2)}&ls_fiyat=${listeFiyatı}0&uc_kuvveti=${kuvvet}&erisim_alani=${erisim}&cfg_coint=${eklem1}|${eklem2}|${eklem3}&cfg_tabla=&cfg_kol=${deltaKol}&cfg_sac=${deltaSac}&cfg_ayak=&cfg_boru=&cfg_kasa=${deltaKasa1}|${deltaKasa2}|${deltaKasa3}&cfg_panel=${deltaPanel}&cfg_pano=2&cfg_fiyat=${fiyat}&format=json`)
                .then(response => {
                    //console.log(response.data)
                    // dispatch(changeStep({stepNumber: 5}));
                    dispatch(changeQuote({ quoteSend: true }));
                })
                .catch( error => {
                    console.log(error);
                })
        } else if (sistem === "cobot") {
            sistemTipi = "cok_eksenli";
            let eksenListesi = '';
            let kasaListesi = '';
            if (eksen === '4') {
                eksenListesi = `${eklem1}|${eklem2}|${eklem3}|${eklem4}`;
                kasaListesi = `${cobotKasa2}|${cobotKasa3}|${cobotKasa4}`; 
            } else if (eksen === '6') {
                eksenListesi = `${eklem1}|${eklem2}|${eklem3}|${eklem4}|${eklem5}|${eklem6}`;
                kasaListesi = `${cobotKasa2}|${cobotKasa3}|${cobotKasa4}|${cobotKasa5}|${cobotKasa6}`; 
            }

            axiosInstance.get(`/save_customer?iletisim=[${contactInfo.name}|${contactInfo.surname}|${contactInfo.phoneNumber}|${contactInfo.email}|${contactInfo.company}]&sistem_tipi=${sistemTipi}&ls_coint=${removeDuplicates(repeatStringNumTimes('20', checkoutCoint20)+'|'+repeatStringNumTimes('50', checkoutCoint50)+'|'+repeatStringNumTimes('100', checkoutCoint100))}&ls_tabla=&ls_kol=&ls_sac=&ls_ayak=${removeDuplicates(repeatStringNumTimes('20', checkoutAyak20)+'|'+repeatStringNumTimes('50', checkoutAyak50)+'|'+repeatStringNumTimes('100', checkoutAyak100))}&ls_boru=${removeDuplicates(repeatStringNumTimes('200', checkoutBoru200)+'|'+repeatStringNumTimes('400', checkoutBoru400)+'|'+repeatStringNumTimes('600', checkoutBoru600))}&ls_kasa=${removeDuplicates(repeatStringNumTimes('20', checkoutKasa20)+'|'+repeatStringNumTimes('50', checkoutKasa50)+'|'+repeatStringNumTimes('100', checkoutKasa100))}&ls_panel=&ls_pano=${repeatStringNumTimes('3', checkoutPano3)}&ls_fiyat=${listeFiyatı}0&uc_kuvveti=${kuvvet}&erisim_alani=${erisim}&cfg_coint=${eksenListesi}&cfg_tabla=&cfg_kol=&cfg_sac=&cfg_ayak=${cobotAyak}&cfg_boru=${cobotBoru1}|${cobotBoru2}&cfg_kasa=${kasaListesi}&cfg_panel=&cfg_pano=3&cfg_fiyat=${fiyat}&format=json`)
                .then(response => {
                    //console.log(response.data)
                    // dispatch(changeStep({stepNumber: 5}));
                    dispatch(changeQuote({ quoteSend: true }));
                })
                .catch( error => {
                    console.log(error);
                })

        }
    }

    const [contactInfo, setContactInfo] = useState(
        {
            name: '',
            surname: '',
            phoneNumber: '',
            email: '',
            company: ''
        }
    );

    const [validity, setValidity] = useState(
        {
            nameValid: true,
            surnameValid: true,
            phoneNumberValid: true,
            emailValid: true,
            companyValid: true
        }
    );

    const handleChange = (newValue) => {
        dispatch(changeStep({stepNumber: newValue}));
      };

    const checkValidity = (value, inputArea) => {
        let isValid = true;
        if (inputArea === "name") {
            isValid = value && isValid;
            setValidity({
                ...validity,
                nameValid: isValid
            })
        }

        if (inputArea === "surname") {
          isValid = value && isValid;
          setValidity({
              ...validity,
              surnameValid: isValid
          })
      }

        if (inputArea === "email") {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid;
            setValidity({
                ...validity,
                emailValid: isValid
            })
        }

        if(inputArea === "phoneNumber") {
            const pattern = /^\d+$/;
            isValid = pattern.test(value) && isValid;
            setValidity({
                ...validity,
                phoneNumberValid: isValid
            })
        }

        if (inputArea === "company") {
          isValid = value && isValid;
          setValidity({
              ...validity,
              companyValid: isValid
          })
      }
    }

    const inputChangeHandler = (event) => {
        
        const value = event.target.value;
        checkValidity(value, event.target.name);
        setContactInfo({
            ...contactInfo,
            [event.target.name]: value
        })
    }

    const [KVKKchecked, setChecked] = React.useState(false);

    const handleChangeCheckbox = (event) => {
        setChecked(event.target.checked);
      };

    return (
            <>
                <div className={classes.rightWrapperBefore}>
                    <div className={classes.rightWrapperlv1Before}>
                        <div className={classes.rightWrapperlv2Before}>
                            <div className={classes.title}>
                                <h2 className={classes.titleh2}>{t('SistemPage.buy')}</h2>
                                <span className={classes.infoText}>{t('SistemPage.buyDesc')}</span>
                            </div>
                            <div className={classes.inputContainer}>
                                <div className={classes.inputs}>
                                    <div className={classes.input}>
                                        <TextField 
                                            fullWidth
                                            name="name"
                                            type="text"
                                            label={t('TeklifAlPage.isim')}
                                            onChange={(event) => inputChangeHandler(event)}
                                            value={contactInfo.name}
                                            helperText={!validity.nameValid ? t('TeklifAlPage.inputErrorİsim') : ""}
                                            error={!validity.nameValid} />
                                    </div>
                                    <div className={classes.input}>
                                        <TextField 
                                            fullWidth
                                            name="surname"
                                            type="text"
                                            label={t('TeklifAlPage.soyisim')}
                                            onChange={(event) => inputChangeHandler(event)}
                                            value={contactInfo.surname}
                                            helperText={!validity.surnameValid ? t('TeklifAlPage.inputErrorSoyisim') : ""}
                                            error={!validity.surnameValid} />
                                    </div>
                                    <div className={classes.input}>
                                        <TextField 
                                            fullWidth
                                            id="email"
                                            label={t('TeklifAlPage.email')}
                                            name="email"
                                            onChange={(event) => inputChangeHandler(event)}
                                            value={contactInfo.email}
                                            helperText={!validity.emailValid ? t('TeklifAlPage.inputErrorEmail') : ""}
                                            error={!validity.emailValid} />
                                    </div>
                                    <div className={classes.input}>
                                        <TextField 
                                            fullWidth 
                                            id="phoneNumber" 
                                            label={t('TeklifAlPage.telefon')}
                                            name="phoneNumber"
                                            onChange={(event) => inputChangeHandler(event)}
                                            value={contactInfo.phoneNumber}
                                            helperText={!validity.phoneNumberValid ? t('TeklifAlPage.inputErrorTelefon') : ""}
                                            error={!validity.phoneNumberValid} />
                                    </div>
                                    <div className={classes.input}>
                                        <TextField 
                                            fullWidth 
                                            id="company" 
                                            label={t('TeklifAlPage.company')}
                                            name="company"
                                            onChange={(event) => inputChangeHandler(event)}
                                            value={contactInfo.company}
                                            helperText={!validity.companyValid ? t('TeklifAlPage.inputErrorİsim') : ""}
                                            error={!validity.companyValid} />
                                    </div>
                                </div>
                                <div>
                                    <div className={classes.checkboxWrapper} >
                                        <div className={classes.checkbox}>
                                            <Checkbox
                                                checked={KVKKchecked}
                                                onChange={handleChangeCheckbox}
                                                color="default"
                                                inputProps={{ 'aria-label': 'checkbox with default color' }}
                                            />
                                            <div className={classes.checkboxLabel}>
                                                <label>
                                                    <p>{t('TeklifAlPage.gizlilik')}</p>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.formwizardUpdate}>
                                <div className={classes.formwizardlv1}>
                                    <div className={classes.buttonContainer}>
                                        <button onClick={() => handleChange(2)} className={classes.backButton}>
                                            <div className={classes.backButtonIcon}>
                                                <IconContext.Provider value={{ color: '#ffffff', size: 16 }}>
                                                <AiOutlineArrowRight />
                                                </IconContext.Provider>
                                            </div>
                                            <span className={classes.startText}>{t('Buton.geri')}</span>
                                        </button>
                                        <button onClick={submitHandler} disabled={!(validity.nameValid && validity.phoneNumberValid && validity.emailValid && KVKKchecked && contactInfo.name && contactInfo.surname && contactInfo.email && contactInfo.phoneNumber )} className={classes.nextButton}>
                                            <span className={classes.finishText}>{t('SistemPage.buy')}</span>
                                            <div className={classes.buttonIcon}>
                                                <IconContext.Provider value={{ color: '#ffffff', size: 16 }}>
                                                <AiOutlineArrowRight />
                                                </IconContext.Provider>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    );
}

export default ContactInfoComp;