import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  erisim: '-',
  kuvvet: '-',
  fiyat: '-',
  listeFiyatı: '-'
};

export const summarySlice = createSlice({
  name: 'summary',
  initialState,
  reducers: {
    changeErisim: (state, action) => {
      state.erisim = action.payload.erisim;
    },
    changeKuvvet: (state, action) => {
      state.kuvvet = action.payload.kuvvet;
    },
    changeFiyat: (state, action) => {
      state.fiyat = action.payload.fiyat;
    },
    changeListeFiyatı: (state, action) => {
      state.listeFiyatı = action.payload.listeFiyatı;
    },
    resetSummary: () => initialState,
  }
})

// Action creators are generated for each case reducer function
export const { changeErisim, changeKuvvet, changeFiyat, changeListeFiyatı, resetSummary } = summarySlice.actions

export default summarySlice.reducer