import { configureStore } from '@reduxjs/toolkit';
import locationReducer from '../../services/products/product/reducer';
import stepReducer from '../../services/products/product/stepReducer';
import userReducer from '../../services/users/userReducer';
import moduleReducer from '../../services/products/product/moduleReducer';
import summaryReducer from '../../services/products/product/summaryReducer';
import cobotReducer from '../../services/products/product/cobotReducer';
import scaraReducer from '../../services/products/product/scaraReducer';
import tablaReducer from '../../services/products/product/tablaReducer';
import deltaReducer from '../../services/products/product/deltaReducer';
import listeReducer from '../../services/products/product/listeReducer';
import checkoutReducer from '../../services/products/product/checkoutReducer';
import priceReducer from '../../services/products/product/priceReducer';

export default configureStore({
  reducer: {
      location: locationReducer,
      steps: stepReducer,
      users: userReducer,
      module: moduleReducer,
      summary: summaryReducer,
      cobot: cobotReducer,
      scara: scaraReducer,
      tabla: tablaReducer,
      delta: deltaReducer,
      list: listeReducer,
      checkout: checkoutReducer,
      price: priceReducer
  }
})