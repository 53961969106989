import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    coint20: 0,
    coint50: 0,
    coint100: 0,
    ayak20: 0,
    ayak50: 0,
    ayak100: 0,
    boru200: 0,
    boru400: 0,
    boru600: 0,
    tabanSacı1: 0,
    tabanSacı2: 0,
    deltaKol1: 0,
    deltaKol2: 0,
    deltaPanel1: 0,
    deltaPanel2: 0,
    tabla1: 0,
    tabla2: 0,
    kasa20: 0,
    kasa50: 0,
    kasa100: 0,
    scaraListKol1: 0,
    scaraListKol2: 0,
    pano1: 1,
    pano2: 1,
    pano3: 1,
};

export const listSlice = createSlice({
  name: 'list',
  initialState,
  reducers: {
    changeCoint20: (state, action) => {
        state.coint20 = action.payload.coint20;
    },
    changeCoint50: (state, action) => {
        state.coint50 = action.payload.coint50;
    },
    changeCoint100: (state, action) => {
        state.coint100 = action.payload.coint100;
    },
    changeAyak20: (state, action) => {
        state.ayak20 = action.payload.ayak20;
    },
    changeAyak50: (state, action) => {
        state.ayak50 = action.payload.ayak50;
    },
    changeAyak100: (state, action) => {
        state.ayak100 = action.payload.ayak100;
    },
    changeBoru200: (state, action) => {
        state.boru200 = action.payload.boru200;
    },
    changeBoru400: (state, action) => {
        state.boru400 = action.payload.boru400;
    },
    changeBoru600: (state, action) => {
        state.boru600 = action.payload.boru600;
    },
    changeTabanSacı1: (state, action) => {
        state.tabanSacı1 = action.payload.tabanSacı1;
    },
    changeTabanSacı2: (state, action) => {
        state.tabanSacı2 = action.payload.tabanSacı2;
    },
    changeDeltaKol1: (state, action) => {
        state.deltaKol1 = action.payload.deltaKol1;
    },
    changeDeltaKol2: (state, action) => {
        state.deltaKol2 = action.payload.deltaKol2;
    },
    changeDeltaPanel1: (state, action) => {
        state.deltaPanel1 = action.payload.deltaPanel1;
    },
    changeDeltaPanel2: (state, action) => {
        state.deltaPanel2 = action.payload.deltaPanel2;
    },
    changeTabla1: (state, action) => {
        state.tabla1 = action.payload.tabla1;
    },
    changeTabla2: (state, action) => {
        state.tabla2 = action.payload.tabla2;
    },
    changeKasa20: (state, action) => {
        state.kasa20 = action.payload.kasa20;
    },
    changeKasa50: (state, action) => {
        state.kasa50 = action.payload.kasa50;
    },
    changeKasa100: (state, action) => {
        state.kasa100 = action.payload.kasa100;
    },
    changeScaraListKol1: (state, action) => {
        state.scaraListKol1 = action.payload.scaraListKol1;
    },
    changeScaraListKol2: (state, action) => {
        state.scaraListKol2 = action.payload.scaraListKol2;
    },
    changePano1: (state, action) => {
        state.pano1 = action.payload.pano1;
    },
    changePano2: (state, action) => {
        state.pano2 = action.payload.pano2;
    },
    changePano3: (state, action) => {
        state.pano3 = action.payload.pano3;
    },
    resetList: () => initialState,
  }
})

// Action creators are generated for each case reducer function
export const {
    changeCoint20,
    changeCoint50,
    changeCoint100,
    changeAyak20,
    changeAyak50,
    changeAyak100,
    changeBoru200,
    changeBoru400,
    changeBoru600,
    changeTabanSacı1,
    changeTabanSacı2,
    changeDeltaKol1,
    changeDeltaKol2,
    changeDeltaPanel1,
    changeDeltaPanel2,
    changeTabla1,
    changeTabla2,
    changeKasa20,
    changeKasa50,
    changeKasa100,
    changeScaraListKol1,
    changeScaraListKol2,
    changePano1,
    changePano2,
    changePano3 
} = listSlice.actions

export default listSlice.reducer