import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  stepNumber: 0,
  quoteSend: false,
  solarvisQuote: false,
  lastInfo: false,
  uploadStepNumber: 0,
  companyProcessNumber: Number(localStorage.getItem('companyProcessNumber')) || 0
};

export const stepSlice = createSlice({
  name: 'steps',
  initialState,
  reducers: {
    changeStep: (state, action) => {
      state.stepNumber = action.payload.stepNumber;
    },
    changeQuote: (state, action) => {
      state.quoteSend = action.payload.quoteSend;
    },
    changeSolarvisQuote: (state, action) => {
      state.solarvisQuote = action.payload.solarvisQuote;
    },
    changeLastInfo: (state, action) => {
      state.lastInfo = action.payload.lastInfo;
    },
    changeUploadStepNumber: (state, action) => {
      state.uploadStepNumber = action.payload.uploadStepNumber;
    },
    changeCompanyProcessNumber: (state, action) => {
      state.companyProcessNumber = action.payload.companyProcessNumber;
      localStorage.setItem('companyProcessNumber', action.payload.companyProcessNumber);
    },
    resetStep: () => initialState,
  }
})

// Action creators are generated for each case reducer function
export const { changeStep, changeQuote, changeSolarvisQuote, changeLastInfo, changeUploadStepNumber, changeCompanyProcessNumber, resetStep } = stepSlice.actions

export default stepSlice.reducer