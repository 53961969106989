import React, { useRef, useState } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import classes from './AddressPage.module.css';
import { IconContext } from 'react-icons/lib';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';
import { changeStep } from '../../services/products/product/stepReducer';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import Select from '@material-ui/core/Select';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { changeSistem, changeEksen } from '../../services/products/product/moduleReducer';
import { resetSummary } from '../../services/products/product/summaryReducer';
import axiosInstance from '../../components/axios/axiosApi';
import {
    changepriceCoint20,
    changepriceCoint50,
    changepriceCoint100,
    changepriceAyak20,
    changepriceAyak50,
    changepriceAyak100,
    changepriceBoru200,
    changepriceBoru400,
    changepriceBoru600,
    changepriceTabanSacı1,
    changepriceTabanSacı2,
    changepriceDeltaKol1,
    changepriceDeltaKol2,
    changepriceDeltaPanel1,
    changepriceDeltaPanel2,
    changepriceTabla1,
    changepriceTabla2,
    changepriceKasa20,
    changepriceKasa50,
    changepriceKasa100,
    changepriceScaraKol1,
    changepriceScaraKol2,
    changepricePano1,
    changepricePano2,
    changepricePano3 
} from '../../services/products/product/priceReducer';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 300,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }))(InputBase);

function AddressPage(props) {

    const { t, } = useTranslation();
    const dispatch = useDispatch();
    const { sistem, eksen } = useSelector(state => state.module);

    const MUIClasses = useStyles();

    const handleChange = (newValue) => {
        dispatch(changeStep({stepNumber: newValue}));
      };

    const selectionChange = (event) => {
        dispatch(changeEksen({eksen: event.target.value}));
      }

    const handleChangeSelect = (event) => {
        dispatch(changeSistem({sistem: event.target.value}));
        if (event.target.value === "cobot") {
            dispatch(changeEksen({eksen: '4'}));
        } else if (event.target.value === "tabla") {
            dispatch(changeEksen({eksen: '1'}));
        } else if (event.target.value === "delta") {
            dispatch(changeEksen({eksen: '3'}));
        } else if (event.target.value === "scara") {
            dispatch(changeEksen({eksen: '2'}));
        }
    };

    async function fetchPrices() {
        await axiosInstance.get('fiyatlar_fetch?format=json')
                .then((response) => {
                    response.data.map((item) => {
                        if (item.ad === 'Coint') {
                            if (item.tur === '20') {
                                dispatch(changepriceCoint20({priceCoint20: item.fiyat}));
                            } else if (item.tur === '50') {
                                dispatch(changepriceCoint50({priceCoint50: item.fiyat}));
                            } else if (item.tur === '100') {
                                dispatch(changepriceCoint100({priceCoint100: item.fiyat}));
                            }
                        } else if (item.ad === 'Ayak') {
                            if (item.tur === '20') {
                                dispatch(changepriceAyak20({priceAyak20: item.fiyat}));
                            } else if (item.tur === '50') {
                                dispatch(changepriceAyak50({priceAyak50: item.fiyat}));
                            } else if (item.tur === '100') {
                                dispatch(changepriceAyak100({priceAyak100: item.fiyat}));
                            }
                        } else if (item.ad === 'Kasa') {
                            if (item.tur === '20') {
                                dispatch(changepriceKasa20({priceKasa20: item.fiyat}));
                            } else if (item.tur === '50') {
                                dispatch(changepriceKasa50({priceKasa50: item.fiyat}));
                            } else if (item.tur === '100') {
                                dispatch(changepriceKasa100({priceKasa100: item.fiyat}));
                            }
                        } else if (item.ad === 'Boru') {
                            if (item.tur === '200') {
                                dispatch(changepriceBoru200({priceBoru200: item.fiyat}));
                            } else if (item.tur === '400') {
                                dispatch(changepriceBoru400({priceBoru400: item.fiyat}));
                            } else if (item.tur === '600') {
                                dispatch(changepriceBoru600({priceBoru600: item.fiyat}));
                            }
                        } else if (item.ad === 'Delta Kol') {
                            if (item.tur === '1') {
                                dispatch(changepriceDeltaKol1({priceDeltaKol1: item.fiyat}));
                            } else if (item.tur === '2') {
                                dispatch(changepriceDeltaKol2({priceDeltaKol2: item.fiyat}));
                            }
                        } else if (item.ad === 'Delta Panel') {
                            if (item.tur === '1') {
                                dispatch(changepriceDeltaPanel1({priceDeltaPanel1: item.fiyat}));
                            } else if (item.tur === '2') {
                                dispatch(changepriceDeltaPanel2({priceDeltaPanel2: item.fiyat}));
                            }
                        } else if (item.ad === 'Tabla') {
                            if (item.tur === '1') {
                                dispatch(changepriceTabla1({priceTabla1: item.fiyat}));
                            } else if (item.tur === '2') {
                                dispatch(changepriceTabla2({priceTabla2: item.fiyat}));
                            }
                        } else if (item.ad === 'Scara Kol') {
                            if (item.tur === '1') {
                                dispatch(changepriceScaraKol1({priceScaraKol1: item.fiyat}));
                            } else if (item.tur === '2') {
                                dispatch(changepriceScaraKol2({priceScaraKol2: item.fiyat}));
                            }
                        } else if (item.ad === 'Taban Sacı') {
                            if (item.tur === '1') {
                                dispatch(changepriceTabanSacı1({priceTabanSacı1: item.fiyat}));
                            } else if (item.tur === '2') {
                                dispatch(changepriceTabanSacı2({priceTabanSacı2: item.fiyat}));
                            }
                        } else if (item.ad === 'Pano') {
                            if (item.tur === '1') {
                                dispatch(changepricePano1({pricePano1: item.fiyat}));
                            } else if (item.tur === '2') {
                                dispatch(changepricePano2({pricePano2: item.fiyat}));
                            } else if (item.tur === '3') {
                                dispatch(changepricePano3({pricePano3: item.fiyat}));
                            }
                        }
                    })
                })
                .catch((error) => console.log(error));
    }

    const willMount = useRef(true);
      if (willMount.current) {
        dispatch(resetSummary());
        fetchPrices();
        willMount.current = false;
      }

    return (
        <>
            <div className={classes.leftWrapper}>
                {sistem === "tabla" && eksen === "1" && <img style={{objectFit: 'contain', width: '100%'}} src="/images/tek_tabla.png" alt="Tek Eksenli Döner Tabla" />}
                {sistem === "tabla" && eksen === "2" && <img style={{objectFit: 'contain', width: '100%'}} src="/images/iki_tabla.png" alt="İki Eksenli Döner Tabla" />}
                {sistem === "scara" && <img style={{objectFit: 'contain', width: '100%'}} src="/images/scara.png" alt="Scara Robot" />}
                {sistem === "delta" && <img style={{objectFit: 'contain', width: '100%'}} src="/images/delta.png" alt="Delta Robot" />}
                {sistem === "cobot" && <img style={{objectFit: 'contain', width: '100%'}} src="/images/alti_cobot.png" alt="Altı Eksenli Cobot" />}
            </div>
            <div className={classes.rightWrapper}>
                <div className={classes.rightWrapperlv1}>
                    <div className={classes.rightWrapperlv2}>
                        <div className={classes.title}>
                            <h2 className={classes.titleh2}>{t('SistemPage.sistemSec')}</h2>
                            <span className={classes.adresGir}>{t('SistemPage.sistemMetin')}</span>
                        </div>
                        <div className={classes.inputAdressWrapper}>
                            <div>
                                <FormControl variant="outlined" className={MUIClasses.formControl}>
                                    {/* <InputLabel id="demo-simple-select-outlined-label"> </InputLabel> */}
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={sistem}
                                        displayEmpty
                                        onChange={handleChangeSelect}
                                        label="Sistem"
                                        notched
                                        input={<BootstrapInput />}
                                    >
                                        <MenuItem value="tabla">{t('SistemPage.turntable')}</MenuItem>
                                        <MenuItem value="scara">Scara Robot</MenuItem>
                                        <MenuItem value="delta">Delta Robot</MenuItem>
                                        <MenuItem value="cobot">Cobot</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        {sistem === "tabla" && 
                        <div className={classes.radioWrapper}>
                            <div className={classes.facilityChoice}>
                                <label className={eksen === "1" ? classes.rooflabelActive : classes.roofLabel}>
                                    <span className={classes.spanRoofLabel}>{t('SistemPage.tekEksen')}</span>
                                    <input 
                                        type="radio" 
                                        id="facility_type1" 
                                        name="facility_type" 
                                        value="1" 
                                        className={classes.radioRoof} 
                                        checked={eksen === "1"}
                                        onChange={selectionChange}
                                    ></input>
                                </label>
                                <label className={eksen === "2" ? classes.rooflabelActive : classes.roofLabel}>
                                    <span className={classes.spanRoofLabel}>{t('SistemPage.ciftEksen')}</span>
                                    <input 
                                        type="radio" 
                                        id="facility_type2" 
                                        name="facility_type" 
                                        value="2" 
                                        className={classes.radioRoof} 
                                        checked={eksen === "2"}
                                        onChange={selectionChange}
                                    ></input>
                                </label>
                            </div>
                        </div>}
                        {sistem === "cobot" && 
                        <div className={classes.radioWrapper}>
                            <div className={classes.facilityChoice}>
                                <label className={eksen === "4" ? classes.rooflabelActive : classes.roofLabel}>
                                    <span className={classes.spanRoofLabel}>{t('SistemPage.dortEksen')}</span>
                                    <input 
                                        type="radio" 
                                        id="facility_type1" 
                                        name="facility_type" 
                                        value="4" 
                                        className={classes.radioRoof} 
                                        checked={eksen === "4"}
                                        onChange={selectionChange}
                                    ></input>
                                </label>
                                <label className={eksen === "6" ? classes.rooflabelActive : classes.roofLabel}>
                                    <span className={classes.spanRoofLabel}>{t('SistemPage.altiEksen')}</span>
                                    <input 
                                        type="radio" 
                                        id="facility_type2" 
                                        name="facility_type" 
                                        value="6" 
                                        className={classes.radioRoof} 
                                        checked={eksen === "6"}
                                        onChange={selectionChange}
                                    ></input>
                                </label>
                            </div>
                        </div>}
                    </div>
                    <div className={classes.formwizard}>
                        <div className={classes.formwizardlv1}>
                            <div className={classes.buttonContainer}>
                                <button onClick={() => handleChange(1)} className={classes.nextButtonActive}>
                                    <span className={classes.startText}>{t('Buton.ileri')}</span>
                                    <div className={classes.buttonIcon}>
                                        <IconContext.Provider value={{ color: '#ffffff', size: 16 }}>
                                        <AiOutlineArrowRight />
                                        </IconContext.Provider>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddressPage;