import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classes from '../DrawingPage/DrawingPage.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { changeStep } from '../../services/products/product/stepReducer';
import { useMediaQuery } from 'react-responsive';
import Divider from '@material-ui/core/Divider';
import { makeStyles, withStyles, alpha } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import { changeListeFiyatı } from '../../services/products/product/summaryReducer';
import { changeAyak100, changeAyak20, changeAyak50, changeBoru200, changeBoru400, changeBoru600, changeCoint100, changeCoint20, changeCoint50, changeDeltaKol1, changeDeltaKol2, changeDeltaPanel1, changeDeltaPanel2, changeKasa100, changeKasa20, changeKasa50, changePano1, changePano3, changeScaraKol1, changeScaraKol2, changeTabanSacı1, changeTabanSacı2, changeTabla1, changeTabla2 } from '../../services/products/product/listeReducer';
import { changeCheckoutAyak100, changeCheckoutAyak20, changeCheckoutAyak50, changeCheckoutBoru200, changeCheckoutBoru400, changeCheckoutBoru600, changeCheckoutCoint100, changeCheckoutCoint20, changeCheckoutCoint50, changeCheckoutDeltaKol1, changeCheckoutDeltaKol2, changeCheckoutDeltaPanel1, changeCheckoutDeltaPanel2, changeCheckoutKasa100, changeCheckoutKasa20, changeCheckoutKasa50, changeCheckoutPano1, changeCheckoutPano3, changeCheckoutScaraKol1, changeCheckoutScaraKol2, changeCheckoutTabanSacı1, changeCheckoutTabanSacı2, changeCheckoutTabla1, changeCheckoutTabla2 } from '../../services/products/product/checkoutReducer';

const MarginDivider = withStyles({
    root: {
        marginTop: 20,
        marginBottom: 20
    },
})(Divider);

const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
      width: '70px'
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: '1px solid #ced4da',
      fontSize: 16,
      width: 'auto',
      padding: '8px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }))(InputBase);

const useStyles = makeStyles({
    table: {
        minWidth: 300,
    },
    cell: {
        padding: 8
    },
    tablePrice: {
        minWidth: 300,
        marginTop: 10
    }
  });

function TablaList(props) {

    const { t, } = useTranslation();
    const dispatch = useDispatch();
    const MUIClasses = useStyles();
    const {
        eksen,
        eklem1,
        eklem2,
    } = useSelector(state => state.module);

    const {
        coint20,
        coint50,
        coint100,
        ayak20,
        ayak50,
        ayak100,
        kasa20,
        kasa50,
        kasa100,
        tabla1,
        tabla2,
        pano1
    } = useSelector(state => state.list);

    const {
        priceCoint20,
        priceCoint50,
        priceCoint100,
        priceAyak20,
        priceAyak50,
        priceAyak100,
        priceTabla1,
        priceTabla2,
        priceKasa20,
        priceKasa50,
        priceKasa100,
        pricePano1,
    } = useSelector(state => state.price);

    const {
        checkoutCoint20,
        checkoutCoint50,
        checkoutCoint100,
        checkoutAyak20,
        checkoutAyak50,
        checkoutAyak100,
        checkoutKasa20,
        checkoutKasa50,
        checkoutKasa100,
        checkoutTabla1,
        checkoutTabla2,
        checkoutPano1
    } = useSelector(state => state.checkout);

    const {
        fiyat,
        listeFiyatı
    } = useSelector(state => state.summary);

    const {
        tabla,
        tablaAyak,
        tablaKasa,
    } = useSelector(state => state.tabla);

    const [priceChanges, setPriceChanges] = useState({});

    //const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'});
    //const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    const willMount = useRef(true);
      if (willMount.current) {
        getList();
        willMount.current = false;
      }

    useEffect(() => {
        let didCancel = false;

        async function getPrice() {
    
            setPriceChanges({
                coint20: {
                    isim: 'Coint-20',
                    adet: Number(checkoutCoint20) - Number(coint20),
                    fiyat: priceCoint20 * (Number(checkoutCoint20) - Number(coint20))
                },
                coint50: {
                    isim: 'Coint-50',
                    adet: Number(checkoutCoint50) - Number(coint50),
                    fiyat: priceCoint50 * (Number(checkoutCoint50) - Number(coint50))
                },
                coint100: {
                    isim: 'Coint-100',
                    adet: Number(checkoutCoint100) - Number(coint100),
                    fiyat: priceCoint100 * (Number(checkoutCoint100) - Number(coint100))
                },
                ayak20: {
                    isim: 'Base-20',
                    adet: Number(checkoutAyak20) - Number(ayak20),
                    fiyat: priceAyak20 * (Number(checkoutAyak20) - Number(ayak20))
                },
                ayak50: {
                    isim: 'Base-50',
                    adet: Number(checkoutAyak50) - Number(ayak50),
                    fiyat: priceAyak50 * (Number(checkoutAyak50) - Number(ayak50))
                },
                ayak100: {
                    isim: 'Base-100',
                    adet: Number(checkoutAyak100) - Number(ayak100),
                    fiyat: priceAyak100 * (Number(checkoutAyak100) - Number(ayak100))
                },
                kasa20: {
                    isim: 'Case-20',
                    adet: Number(checkoutKasa20) - Number(kasa20),
                    fiyat: priceKasa20 * (Number(checkoutKasa20) - Number(kasa20))
                },
                kasa50: {
                    isim: 'Case-50',
                    adet: Number(checkoutKasa50) - Number(kasa50),
                    fiyat: priceKasa50 * (Number(checkoutKasa50) - Number(kasa50))
                },
                kasa100: {
                    isim: 'Case-100',
                    adet: Number(checkoutKasa100) - Number(kasa100),
                    fiyat: priceKasa100 * (Number(checkoutKasa100) - Number(kasa100))
                },
                tabla1: {
                    isim: 'Table-1',
                    adet: Number(checkoutTabla1) - Number(tabla1),
                    fiyat: priceTabla1 * (Number(checkoutTabla1) - Number(tabla1))
                },
                tabla2: {
                    isim: 'Table-2',
                    adet: Number(checkoutTabla2) - Number(tabla2),
                    fiyat: priceTabla2 * (Number(checkoutTabla2) - Number(tabla2))
                },
                pano1: {
                    isim: 'Cabinet-1',
                    adet: Number(checkoutPano1) - Number(pano1),
                    fiyat: pricePano1 * (Number(checkoutPano1) - Number(pano1))
                },
            })
            
            /* if (Number(coint20) !== Number(checkoutCoint20)) {
                await axiosInstance.get(`fiyatlar_fetch?ad=Coint&tur=20&format=json`)
                    .then((response) => {
                        setPriceChanges({
                            ...priceChanges,
                            coint20: {
                                isim: 'Coint-20',
                                adet: Number(checkoutCoint20) - Number(coint20),
                                fiyat: Number(response.data.fiyat) * (Number(checkoutCoint20) - Number(coint20))
                            }
                        });
                    })
                    .catch((error) => console.log(error));
            } else {
                setPriceChanges({
                    ...priceChanges,
                    coint20: {
                        isim: 'Coint-20',
                        adet: 0,
                        fiyat: 0
                    }
                });
            }

            if (Number(coint50) !== Number(checkoutCoint50)) {
                await axiosInstance.get(`fiyatlar_fetch?ad=Coint&tur=50&format=json`)
                    .then((response) => {
                        setPriceChanges({
                            ...priceChanges,
                            coint50: {
                                isim: 'Coint-50',
                                adet: Number(checkoutCoint50) - Number(coint50),
                                fiyat: Number(response.data.fiyat) * (Number(checkoutCoint50) - Number(coint50))
                            }
                        });
                    })
                    .catch((error) => console.log(error));
            } else {
                setPriceChanges({
                    ...priceChanges,
                    coint50: {
                        isim: 'Coint-50',
                        adet: 0,
                        fiyat: 0
                    }
                });
            } */
            
        }

        getPrice();

        return () => { didCancel = true; }; // Remember if we start fetching something else

    }, [checkoutCoint20, checkoutCoint50, checkoutCoint100, checkoutAyak20, checkoutAyak50, checkoutAyak100, checkoutKasa20, checkoutKasa50, checkoutKasa100, checkoutTabla1, checkoutTabla2, checkoutPano1])

    useEffect(() => {
        let didCancel = false;

        async function getPrice() {
            let tempFiyat = 0;
            Object.entries(priceChanges).filter(([key, val]) => Number(val.adet) !== 0).map(([key,row]) => {
                tempFiyat += row.fiyat;
            }); 
            dispatch(changeListeFiyatı({listeFiyatı: Number(fiyat.replace('.','')) + tempFiyat}))
        }

        getPrice();

        return () => { didCancel = true; }; // Remember if we start fetching something else

    }, [priceChanges])


    const handleChange = (event) => {
        if (event.target.name === "Coint-20") {
            dispatch(changeCheckoutCoint20({checkoutCoint20: event.target.value}));
        } else if (event.target.name === "Coint-50") {
            dispatch(changeCheckoutCoint50({checkoutCoint50: event.target.value}));
        } else if (event.target.name === "Coint-100") {
            dispatch(changeCheckoutCoint100({checkoutCoint100: event.target.value}));
        } else if (event.target.name === "Base-20") {
            dispatch(changeCheckoutAyak20({checkoutAyak20: event.target.value}));
        } else if (event.target.name === "Base-50") {
            dispatch(changeCheckoutAyak50({checkoutAyak50: event.target.value}));
        } else if (event.target.name === "Base-100") {
            dispatch(changeCheckoutAyak100({checkoutAyak100: event.target.value}));
        } else if (event.target.name === "Case-20") {
            dispatch(changeCheckoutKasa20({checkoutKasa20: event.target.value}));
        } else if (event.target.name === "Case-50") {
            dispatch(changeCheckoutKasa50({checkoutKasa50: event.target.value}));
        } else if (event.target.name === "Case-100") {
            dispatch(changeCheckoutKasa100({checkoutKasa100: event.target.value}));
        } else if (event.target.name === "Table 1") {
            dispatch(changeCheckoutTabla1({checkoutTabla1: event.target.value}));
        } else if (event.target.name === "Table 2") {
            dispatch(changeCheckoutTabla2({checkoutTabla2: event.target.value}));
        } else if (event.target.name === "Cabinet-1") {
            dispatch(changeCheckoutPano1({checkoutPano1: event.target.value}));
        }
      };

    function createData(name, pieces, listPrice) {
    return { name, pieces, listPrice};
    }
    
    const rows = [
        createData('Coint-20', checkoutCoint20, priceCoint20),
        createData('Coint-50', checkoutCoint50, priceCoint50),
        createData('Coint-100', checkoutCoint100, priceCoint100),
        createData('Table 1', checkoutTabla1, priceTabla1),
        createData('Table 2', checkoutTabla2, priceTabla2),
        createData('Base-20', checkoutAyak20, priceAyak20),
        createData('Base-50', checkoutAyak50, priceAyak50),
        createData('Base-100', checkoutAyak100, priceAyak100),
        createData('Case-20', checkoutKasa20, priceKasa20),
        createData('Case-50', checkoutKasa50, priceKasa50),
        createData('Case-100', checkoutKasa100, priceKasa100),
        createData('Cabinet-1', checkoutPano1, pricePano1),
    ];

    function getList() {

        // Tabla Coint
        let tempCoint20 = 0;
        let tempCoint50 = 0;
        let tempCoint100 = 0;
        if (eksen === '1') {
            if (eklem1 === 20) {
                tempCoint20 += 1;
            } else if (eklem1 === 50) {
                tempCoint50 += 1;
            } else if (eklem1 === 100) {
                tempCoint100 += 1;
            }
        } else if (eksen === '2') {
            [eklem1, eklem2].forEach((eklem) => {
                if (eklem === 20) {
                    tempCoint20 += 1;
                } else if (eklem === 50) {
                    tempCoint50 += 1;
                } else if (eklem === 100) {
                    tempCoint100 += 1;
                }
            })
        }

        // Tabla Kasa
        let tempKasa20 = 0;
        let tempKasa50 = 0;
        let tempKasa100 = 0;
        if (eksen === '2') {
            if (tablaKasa === 20) {
                tempKasa20 += 1;
            } else if (tablaKasa === 50) {
                tempKasa50 += 1;
            } else if (tablaKasa === 100) {
                tempKasa100 += 1;
            }
        }

        // Tabla Ayak
        let tempAyak20 = 0;
        let tempAyak50 = 0;
        let tempAyak100 = 0;
        if (tablaAyak === 20) {
            tempAyak20 += 1;
        } else if (tablaAyak === 50) {
            tempAyak50 += 1;
        } else if (tablaAyak === 100) {
            tempAyak100 += 1;
        }

        // Tabla 
        let tempTabla1 = 0;
        let tempTabla2 = 0;
        if (tabla === 1) {
            tempTabla1 += 1;
        } else if (tabla === 2) {
            tempTabla2 += 1;
        }


        // State değişimleri
        dispatch(changeCoint20({coint20: tempCoint20}));
        dispatch(changeCoint50({coint50: tempCoint50}));
        dispatch(changeCoint100({coint100: tempCoint100}));

        dispatch(changeKasa20({kasa20: tempKasa20}));
        dispatch(changeKasa50({kasa50: tempKasa50}));
        dispatch(changeKasa100({kasa100: tempKasa100}));

        dispatch(changeAyak20({ayak20: tempAyak20}));
        dispatch(changeAyak50({ayak50: tempAyak50}));
        dispatch(changeAyak100({ayak100: tempAyak100}));

        dispatch(changeTabla1({tabla1: tempTabla1}));
        dispatch(changeTabla2({tabla2: tempTabla2}));

        // checkout
        dispatch(changeCheckoutCoint20({checkoutCoint20: tempCoint20}));
        dispatch(changeCheckoutCoint50({checkoutCoint50: tempCoint50}));
        dispatch(changeCheckoutCoint100({checkoutCoint100: tempCoint100}));

        dispatch(changeCheckoutKasa20({checkoutKasa20: tempKasa20}));
        dispatch(changeCheckoutKasa50({checkoutKasa50: tempKasa50}));
        dispatch(changeCheckoutKasa100({checkoutKasa100: tempKasa100}));

        dispatch(changeCheckoutAyak20({checkoutAyak20: tempAyak20}));
        dispatch(changeCheckoutAyak50({checkoutAyak50: tempAyak50}));
        dispatch(changeCheckoutAyak100({checkoutAyak100: tempAyak100}));

        dispatch(changeCheckoutTabla1({checkoutTabla1: tempTabla1}));
        dispatch(changeCheckoutTabla2({checkoutTabla2: tempTabla2}));
    }

    return (
        <>
            <div>
                <TableContainer>
                    <Table className={MUIClasses.table} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell className={MUIClasses.cell}>{t('SistemPage.productName')}</TableCell>
                            <TableCell className={MUIClasses.cell} align="right">{t('SistemPage.quantity')}</TableCell>
                            <TableCell className={MUIClasses.cell} align="right">{t('SistemPage.unitPrice')}</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {rows.filter(row => row.pieces !== 0).map((row) => (
                            <TableRow key={row.name}>
                            <TableCell className={MUIClasses.cell} component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell className={MUIClasses.cell} align="right">
                            <FormControl>
                                <BootstrapInput
                                    name={row.name}
                                    type="number"
                                    //defaultValue={row.pieces}
                                    value={row.pieces}
                                    id="bootstrap-input"
                                    inputProps={{  min: 0, max: 8, step: 1 }}
                                    onChange={handleChange}
                                    />
                            </FormControl>
                            </TableCell>
                            <TableCell className={MUIClasses.cell} align="right">
                                {row.listPrice} €
                            </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <h4 className={classes.titleh2} style={{ marginTop: '0.75rem', fontSize: '1.1rem', textAlign: 'end' }}>{t('SistemPage.fixedConfigurationPrice')} {fiyat.replace('.','')} €</h4>
                <TableContainer>
                    <Table className={MUIClasses.tablePrice} aria-label="simple table">
                        <TableBody>
                        {(Object.entries(priceChanges)).filter(([key, val]) => Number(val.adet) !== 0).map(([key,row]) => (
                            Boolean(Number(row.adet)) &&
                            <TableRow key={row.isim}>
                            <TableCell className={MUIClasses.cell} component="th" scope="row">
                                {row.isim}
                            </TableCell>
                            <TableCell className={MUIClasses.cell} align="right">
                                {(row.adet)>0 ? `+${row.adet}` : `${row.adet}`}x
                            </TableCell>
                            <TableCell className={MUIClasses.cell} align="right">
                                {(row.fiyat)>0 ? `+${row.fiyat}`: `${row.fiyat}`} €
                            </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            {(Number(listeFiyatı) !== Number(fiyat.replace('.',''))) && <h4 className={classes.titleh2} style={{ marginTop: '0.75rem', fontSize: '1.1rem', textAlign: 'end' }}>{t('SistemPage.listPrice')} {listeFiyatı} €</h4>}
        </>
    );
}

export default TablaList;