import React from 'react';
import ReactDOM from 'react-dom';
import store from './store/store'
import { Provider } from 'react-redux'
import App from './App';
import './i18n';

ReactDOM.render(
    <React.Suspense fallback="">
        <Provider store={store}>
            <App />
        </Provider>
    </React.Suspense>
    , 
    document.getElementById('root')
);
