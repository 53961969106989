import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tabla: 1,
  tablaAyak: 100,
  tablaPano: 1,
  tablaKasa: 100
};

export const tablaSlice = createSlice({
  name: 'tabla',
  initialState,
  reducers: {
    changeTabla: (state, action) => {
      state.tabla = action.payload.tabla;
    },
    changeTablaAyak: (state, action) => {
      state.tablaAyak = action.payload.tablaAyak;
    },
    changeTablaPano: (state, action) => {
      state.tablaPano = action.payload.tablaPano;
    },
    changeTablaKasa: (state, action) => {
      state.tablaKasa = action.payload.tablaKasa;
    },
  }
})

// Action creators are generated for each case reducer function
export const {
    changeTabla,
    changeTablaAyak,
    changeTablaPano,
    changeTablaKasa
} = tablaSlice.actions

export default tablaSlice.reducer