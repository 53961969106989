import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    checkoutCoint20: 0,
    checkoutCoint50: 0,
    checkoutCoint100: 0,
    checkoutAyak20: 0,
    checkoutAyak50: 0,
    checkoutAyak100: 0,
    checkoutBoru200: 0,
    checkoutBoru400: 0,
    checkoutBoru600: 0,
    checkoutTabanSacı1: 0,
    checkoutTabanSacı2: 0,
    checkoutDeltaKol1: 0,
    checkoutDeltaKol2: 0,
    checkoutDeltaPanel1: 0,
    checkoutDeltaPanel2: 0,
    checkoutTabla1: 0,
    checkoutTabla2: 0,
    checkoutKasa20: 0,
    checkoutKasa50: 0,
    checkoutKasa100: 0,
    checkoutScaraKol1: 0,
    checkoutScaraKol2: 0,
    checkoutPano1: 1,
    checkoutPano2: 1,
    checkoutPano3: 1,
};

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    changeCheckoutCoint20: (state, action) => {
        state.checkoutCoint20 = action.payload.checkoutCoint20;
    },
    changeCheckoutCoint50: (state, action) => {
        state.checkoutCoint50 = action.payload.checkoutCoint50;
    },
    changeCheckoutCoint100: (state, action) => {
        state.checkoutCoint100 = action.payload.checkoutCoint100;
    },
    changeCheckoutAyak20: (state, action) => {
        state.checkoutAyak20 = action.payload.checkoutAyak20;
    },
    changeCheckoutAyak50: (state, action) => {
        state.checkoutAyak50 = action.payload.checkoutAyak50;
    },
    changeCheckoutAyak100: (state, action) => {
        state.checkoutAyak100 = action.payload.checkoutAyak100;
    },
    changeCheckoutBoru200: (state, action) => {
        state.checkoutBoru200 = action.payload.checkoutBoru200;
    },
    changeCheckoutBoru400: (state, action) => {
        state.checkoutBoru400 = action.payload.checkoutBoru400;
    },
    changeCheckoutBoru600: (state, action) => {
        state.checkoutBoru600 = action.payload.checkoutBoru600;
    },
    changeCheckoutTabanSacı1: (state, action) => {
        state.checkoutTabanSacı1 = action.payload.checkoutTabanSacı1;
    },
    changeCheckoutTabanSacı2: (state, action) => {
        state.checkoutTabanSacı2 = action.payload.checkoutTabanSacı2;
    },
    changeCheckoutDeltaKol1: (state, action) => {
        state.checkoutDeltaKol1 = action.payload.checkoutDeltaKol1;
    },
    changeCheckoutDeltaKol2: (state, action) => {
        state.checkoutDeltaKol2 = action.payload.checkoutDeltaKol2;
    },
    changeCheckoutDeltaPanel1: (state, action) => {
        state.checkoutDeltaPanel1 = action.payload.checkoutDeltaPanel1;
    },
    changeCheckoutDeltaPanel2: (state, action) => {
        state.checkoutDeltaPanel2 = action.payload.checkoutDeltaPanel2;
    },
    changeCheckoutTabla1: (state, action) => {
        state.checkoutTabla1 = action.payload.checkoutTabla1;
    },
    changeCheckoutTabla2: (state, action) => {
        state.checkoutTabla2 = action.payload.checkoutTabla2;
    },
    changeCheckoutKasa20: (state, action) => {
        state.checkoutKasa20 = action.payload.checkoutKasa20;
    },
    changeCheckoutKasa50: (state, action) => {
        state.checkoutKasa50 = action.payload.checkoutKasa50;
    },
    changeCheckoutKasa100: (state, action) => {
        state.checkoutKasa100 = action.payload.checkoutKasa100;
    },
    changeCheckoutScaraKol1: (state, action) => {
        state.checkoutScaraKol1 = action.payload.checkoutScaraKol1;
    },
    changeCheckoutScaraKol2: (state, action) => {
        state.checkoutScaraKol2 = action.payload.checkoutScaraKol2;
    },
    changeCheckoutPano1: (state, action) => {
        state.checkoutPano1 = action.payload.checkoutPano1;
    },
    changeCheckoutPano2: (state, action) => {
        state.checkoutPano2 = action.payload.checkoutPano2;
    },
    changeCheckoutPano3: (state, action) => {
        state.checkoutPano3 = action.payload.checkoutPano3;
    },
    resetCheckoutList: () => initialState,
  }
})

// Action creators are generated for each case reducer function
export const {
    changeCheckoutCoint20,
    changeCheckoutCoint50,
    changeCheckoutCoint100,
    changeCheckoutAyak20,
    changeCheckoutAyak50,
    changeCheckoutAyak100,
    changeCheckoutBoru200,
    changeCheckoutBoru400,
    changeCheckoutBoru600,
    changeCheckoutTabanSacı1,
    changeCheckoutTabanSacı2,
    changeCheckoutDeltaKol1,
    changeCheckoutDeltaKol2,
    changeCheckoutDeltaPanel1,
    changeCheckoutDeltaPanel2,
    changeCheckoutTabla1,
    changeCheckoutTabla2,
    changeCheckoutKasa20,
    changeCheckoutKasa50,
    changeCheckoutKasa100,
    changeCheckoutScaraKol1,
    changeCheckoutScaraKol2,
    changeCheckoutPano1,
    changeCheckoutPano2,
    changeCheckoutPano3 
} = checkoutSlice.actions

export default checkoutSlice.reducer