import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    changeTabla,
} from '../../services/products/product/tablaReducer';
import classes from './DrawingPage.module.css';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 150,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }))(InputBase);

function DeltaSelects(props) {

    const { t, } = useTranslation();
    const dispatch = useDispatch();

    const MUIClasses = useStyles();

    const {
        tabla,
        tablaAyak,
        tablaPano,
        tablaKasa
    } = useSelector(state => state.tabla);
    
    const {
        sistem,
        eksen
    } = useSelector(state => state.module);

    const handleChangeSelect = (event) => {
        if (event.target.name === "tabla") {
            dispatch(changeTabla({tabla: event.target.value}));
        }
    };

    return (
        <>
            <div className={classes.alignItemsFlex}>
                <span className={classes.categorySpan}>Table:</span>
                <FormControl variant="outlined" className={MUIClasses.formControl}>
                {/* <InputLabel id="demo-simple-select-outlined-label">Değer</InputLabel> */}
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={tabla}
                        displayEmpty
                        onChange={handleChangeSelect}
                        label="Değer"
                        notched
                        input={<BootstrapInput />}
                        name="tabla"
                    >
                        <MenuItem value={1}>Table 1</MenuItem>
                        <MenuItem value={2}>Table 2</MenuItem>
                    </Select>
                </FormControl>
            </div>
        </>
    );
}

export default DeltaSelects;