import React, { useRef, useState } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    changeEklem1,
    changeEklem2,
    changeEklem3,
    changeEklem4,
    changeEklem5,
    changeEklem6
} from '../services/products/product/moduleReducer';
import { changeAyak, changeCobotKasa2, changeCobotKasa3, changeCobotKasa4, changeCobotKasa5, changeCobotKasa6 } from '../services/products/product/cobotReducer';
import { changeTablaAyak, changeTablaKasa } from '../services/products/product/tablaReducer';
import { changeScaraAyak } from '../services/products/product/scaraReducer';
import { changeDeltaKasa1, changeDeltaKasa2, changeDeltaKasa3 } from '../services/products/product/deltaReducer';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 150,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }))(InputBase);

function EklemSelect(props) {

    const { t, } = useTranslation();
    const dispatch = useDispatch();

    const MUIClasses = useStyles();

    const handleChangeSelect = (event) => {
        if (props.eklemNumber === 1) {
            dispatch(changeEklem1({eklem1: event.target.value}));
            dispatch(changeAyak({cobotAyak: event.target.value}));
            dispatch(changeTablaAyak({tablaAyak: event.target.value}));
            dispatch(changeScaraAyak({scaraAyak: event.target.value}));
            dispatch(changeDeltaKasa1({deltaKasa1: event.target.value}));
        } else if (props.eklemNumber === 2) {
            dispatch(changeEklem2({eklem2: event.target.value}));
            dispatch(changeTablaKasa({tablaKasa: event.target.value}));
            dispatch(changeCobotKasa2({cobotKasa2: event.target.value}));
            dispatch(changeDeltaKasa2({deltaKasa2: event.target.value}));
        } else if (props.eklemNumber === 3) {
            dispatch(changeEklem3({eklem3: event.target.value}));
            dispatch(changeCobotKasa3({cobotKasa3: event.target.value}));
            dispatch(changeDeltaKasa3({deltaKasa3: event.target.value}));
        } else if (props.eklemNumber === 4) {
            dispatch(changeEklem4({eklem4: event.target.value}));
            dispatch(changeCobotKasa4({cobotKasa4: event.target.value}));
        } else if (props.eklemNumber === 5) {
            dispatch(changeEklem5({eklem5: event.target.value}));
            dispatch(changeCobotKasa5({cobotKasa5: event.target.value}));
        } else if (props.eklemNumber === 6) {
            dispatch(changeEklem6({eklem6: event.target.value}));
            dispatch(changeCobotKasa6({cobotKasa6: event.target.value}));
        }
    };

    return (
        <>
            <FormControl variant="outlined" className={MUIClasses.formControl}>
                {/* <InputLabel id="demo-simple-select-outlined-label">Değer</InputLabel> */}
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={props.deger}
                    displayEmpty
                    onChange={handleChangeSelect}
                    label="Değer"
                    notched
                    input={<BootstrapInput />}
                >
                    <MenuItem value={20}>Coint-20</MenuItem>
                    <MenuItem value={50}>Coint-50</MenuItem>
                    <MenuItem value={100}>Coint-100</MenuItem>
                </Select>
            </FormControl>
        </>
    );
}

export default EklemSelect;