/* eslint-disable prefer-template */
/* eslint-disable object-shorthand */
/* eslint-disable no-else-return */
import axios from 'axios';

const baseURL = 'https://api.integva.com/';

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json'
  }
});

export default axiosInstance;