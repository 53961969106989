import React, { useState, useRef, useEffect } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import classes from './DrawingPage.module.css';
import { IconContext } from 'react-icons/lib';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';
import { changeStep } from '../../services/products/product/stepReducer';
import { useMediaQuery } from 'react-responsive';
import Divider from '@material-ui/core/Divider';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import EklemSelect from '../../components/EklemSelect';
import CobotSelects from './CobotSelects';
import DeltaSelects from './DeltaSelects';
import ScaraSelects from './ScaraSelects';
import TablaSelects from './TablaSelects';
import axiosInstance from '../../components/axios/axiosApi';
import { changeErisim, changeKuvvet, changeFiyat } from '../../services/products/product/summaryReducer';

const MarginDivider = withStyles({
    root: {
        marginTop: 20,
        marginBottom: 20
    },
})(Divider);

function DrawingPage(props) {

    const { t, } = useTranslation();
    const dispatch = useDispatch();
    const {
        sistem,
        eksen,
        eklem1,
        eklem2,
        eklem3,
        eklem4,
        eklem5,
        eklem6
    } = useSelector(state => state.module);

    const {
        scaraKol1,
        scaraKol2,
        scaraAyak,
        scaraPano
    } = useSelector(state => state.scara);

    const {
        tabla,
        tablaAyak,
        tablaKasa,
        tablaPano
    } = useSelector(state => state.tabla);

    const {
        cobotAyak,
        cobotBoru1,
        cobotBoru2,
        cobotPano
    } = useSelector(state => state.cobot);

    const {
        deltaKol,
        deltaSac,
        deltaPanel,
        deltaPano
    } = useSelector(state => state.delta);

    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'});
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    const willMount = useRef(true);
      if (willMount.current) {
        /* ReactGA.event({
            category: 'sayfa_goruntuleme',
            action: 'Çatı Alanı',
          }); */
        //ReactGA.pageview("/cati_alani");
        willMount.current = false;
      }

    const handleChange = (newValue) => {
        dispatch(changeStep({stepNumber: newValue}));
      };

    useEffect(() => {
        let didCancel = false;

        async function getOutput() {
            let cointArray;
            if (eksen === '1') {
                cointArray = `[${eklem1}]`;
            } else if (eksen === '2') {
                cointArray = `[${eklem1}|${eklem2}]`;
            } else if (eksen === '3') {
                cointArray = `[${eklem1}|${eklem2}|${eklem3}]`;
            } else if (eksen === '4') {
                cointArray = `[${eklem1}|${eklem2}|${eklem3}|${eklem4}]`;
            } else if (eksen === '5') {
                cointArray = `[${eklem1}|${eklem2}|${eklem3}|${eklem4}|${eklem5}]`;
            } else if (eksen === '6') {
                cointArray = `[${eklem1}|${eklem2}|${eklem3}|${eklem4}|${eklem5}|${eklem6}]`;
            }
    
            if (sistem === "delta") {
                await axiosInstance.get(`delta_robot?coint=${cointArray}&kol=[${deltaKol}]&sac=[${deltaSac}]&panel=[${deltaPanel}]&pano=[${deltaPano}]&format=json`)
                    .then((response) => {
                        dispatch(changeErisim({erisim: response.data.erisim_alani}));
                        dispatch(changeKuvvet({kuvvet: response.data.uc_kuvveti}));
                        dispatch(changeFiyat({fiyat: response.data.fiyat}));
                    })
                    .catch((error) => console.log(error));
            } else if (sistem === 'cobot') {
                await axiosInstance.get(`cok_eksenli?coint=${cointArray}&ayak=[${cobotAyak}]&boru=[${cobotBoru1}|${cobotBoru2}]&pano=[${cobotPano}]&format=json`)
                    .then((response) => {
                        dispatch(changeErisim({erisim: response.data.erisim_alani}));
                        dispatch(changeKuvvet({kuvvet: response.data.uc_kuvveti}));
                        dispatch(changeFiyat({fiyat: response.data.fiyat}));
                    })
                    .catch((error) => console.log(error));
            } else if (sistem === 'scara') {
                await axiosInstance.get(`scara_robot?coint=${cointArray}&kol=[${scaraKol1}|${scaraKol2}]&ayak=[${scaraAyak}]&pano=[${scaraPano}]&format=json`)
                    .then((response) => {
                        dispatch(changeErisim({erisim: response.data.erisim_alani}));
                        dispatch(changeKuvvet({kuvvet: response.data.uc_kuvveti}));
                        dispatch(changeFiyat({fiyat: response.data.fiyat}));
                    })
                    .catch((error) => console.log(error));
            } else if (sistem === 'tabla') {
                await axiosInstance.get(`doner_tabla?coint=${cointArray}&ayak=[${cobotAyak}]&tabla=[${tabla}]&ayak=[${tablaAyak}]&kasa=[${tablaKasa}]&pano=[${tablaPano}]&format=json`)
                    .then((response) => {
                        dispatch(changeErisim({erisim: response.data.erisim_alani}));
                        dispatch(changeKuvvet({kuvvet: response.data.uc_kuvveti}));
                        dispatch(changeFiyat({fiyat: response.data.fiyat}));
                    })
                    .catch((error) => console.log(error));
            }
        }

        getOutput();

        return () => { didCancel = true; }; // Remember if we start fetching something else

    }, [sistem, eksen, eklem1, eklem2, eklem3, eklem4, eklem5, eklem6, scaraKol1, scaraKol2, scaraAyak, tabla, tablaAyak, tablaKasa, cobotAyak, cobotBoru1, cobotBoru2, deltaKol, deltaSac, deltaPanel])

    return (
        <>
            <div className={classes.leftWrapper}>
                {sistem === "tabla" && eksen === "1" && <img className={classes.imageItem} src="/images/tek_tabla.png" alt="Tek Eksenli Döner Tabla" />}
                {sistem === "tabla" && eksen === "2" && <img className={classes.imageItem} src="/images/iki_tabla.png" alt="İki Eksenli Döner Tabla" />}
                {sistem === "scara" && <img className={classes.imageItem} src="/images/scara.png" alt="Scara Robot" />}
                {sistem === "delta" && <img className={classes.imageItem} src="/images/delta.png" alt="Delta Robot" />}
                {sistem === "cobot" && <img className={classes.imageItem} src="/images/boyutlar.PNG" alt="Altı Eksenli Cobot" />}
            </div>
            <div className={classes.rightWrapper}>
                <div className={classes.rightWrapperlv1}>
                    <div className={classes.rightWrapperlv2}>
                        <div className={classes.title}>
                            <h2 className={classes.titleh2}>{t('SistemPage.configuration')}</h2>
                            <div className={classes.rowFlexWrapper}>
                                <div className={classes.fiftyPercentDiv}>
                                    {Number(eksen) >= 1 &&
                                    <div className={classes.alignItemsFlex}>
                                        <span className={classes.categorySpan}>{t('SistemPage.joint')} 1:</span>
                                        <EklemSelect deger={eklem1} eklemNumber={1}/>
                                    </div>}
                                    {Number(eksen) >= 2 &&
                                    <div className={classes.alignItemsFlex}>
                                        <span className={classes.categorySpan}>{t('SistemPage.joint')} 2:</span>
                                        <EklemSelect deger={eklem2} eklemNumber={2}/>
                                    </div>}
                                    {Number(eksen) >= 3 &&
                                    <div className={classes.alignItemsFlex}>
                                        <span className={classes.categorySpan}>{t('SistemPage.joint')} 3:</span>
                                        <EklemSelect deger={eklem3} eklemNumber={3}/>
                                    </div>}
                                    {Number(eksen) >= 4 &&
                                    <div className={classes.alignItemsFlex}>
                                        <span className={classes.categorySpan}>{t('SistemPage.joint')} 4:</span>
                                        <EklemSelect deger={eklem4} eklemNumber={4}/>
                                    </div>}
                                    {Number(eksen) >= 5 &&
                                    <div className={classes.alignItemsFlex}>
                                        <span className={classes.categorySpan}>{t('SistemPage.joint')} 5:</span>
                                        <EklemSelect deger={eklem5} eklemNumber={5}/>
                                    </div>}
                                    {Number(eksen) >= 6 &&
                                    <div className={classes.alignItemsFlex}>
                                        <span className={classes.categorySpan}>{t('SistemPage.joint')} 6:</span>
                                        <EklemSelect deger={eklem6} eklemNumber={6}/>
                                    </div>}
                                </div>
                                <div className={classes.fiftyPercentDiv}>
                                    {sistem === "cobot" && <CobotSelects />}
                                    {sistem === "delta" && <DeltaSelects />}
                                    {sistem === "scara" && <ScaraSelects />}
                                    {sistem === "tabla" && <TablaSelects />}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.formwizard}>
                        <div className={classes.formwizardlv1}>
                            <div className={classes.buttonContainer}>
                                <button onClick={() => handleChange(0)} className={classes.backButton}>
                                    <div className={classes.backButtonIcon}>
                                        <IconContext.Provider value={{ color: '#ffffff', size: 16 }}>
                                        <AiOutlineArrowRight />
                                        </IconContext.Provider>
                                    </div>
                                    <span className={classes.startText}>{t('Buton.geri')}</span>
                                </button>
                                <button onClick={() => handleChange(2)} className={classes.nextButton}>
                                    <span className={classes.startText}>{t('Buton.ileri')}</span>
                                    <div className={classes.buttonIcon}>
                                        <IconContext.Provider value={{ color: '#ffffff', size: 16 }}>
                                        <AiOutlineArrowRight />
                                        </IconContext.Provider>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DrawingPage;