import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cobotAyak: 100,
  cobotBoru1: 200,
  cobotBoru2: 200,
  cobotPano: 3,
  cobotKasa2: 100,
  cobotKasa3: 50,
  cobotKasa4: 50,
  cobotKasa5: 50,
  cobotKasa6: 50
};

export const cobotSlice = createSlice({
  name: 'cobot',
  initialState,
  reducers: {
    changeAyak: (state, action) => {
      state.cobotAyak = action.payload.cobotAyak;
    },
    changeBoru1: (state, action) => {
      state.cobotBoru1 = action.payload.cobotBoru1;
    },
    changeBoru2: (state, action) => {
      state.cobotBoru2 = action.payload.cobotBoru2;
    },
    changePano: (state, action) => {
      state.cobotPano = action.payload.cobotPano;
    },
    changeCobotKasa2: (state, action) => {
      state.cobotKasa2 = action.payload.cobotKasa2;
    },
    changeCobotKasa3: (state, action) => {
      state.cobotKasa3 = action.payload.cobotKasa3;
    },
    changeCobotKasa4: (state, action) => {
      state.cobotKasa4 = action.payload.cobotKasa4;
    },
    changeCobotKasa5: (state, action) => {
      state.cobotKasa5 = action.payload.cobotKasa5;
    },
    changeCobotKasa6: (state, action) => {
      state.cobotKasa6 = action.payload.cobotKasa6;
    },
  }
})

// Action creators are generated for each case reducer function
export const {
  changeAyak,
  changeBoru1,
  changeBoru2,
  changePano,
  changeCobotKasa2,
  changeCobotKasa3,
  changeCobotKasa4,
  changeCobotKasa5,
  changeCobotKasa6
} = cobotSlice.actions

export default cobotSlice.reducer