import React, { useState, useRef } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import classes from '../DrawingPage/DrawingPage.module.css';
import { IconContext } from 'react-icons/lib';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';
import { changeStep } from '../../services/products/product/stepReducer';
import { useMediaQuery } from 'react-responsive';
import Divider from '@material-ui/core/Divider';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import TableSelect from './TableSelect';
import { changeErisim, changeKuvvet, changeFiyat } from '../../services/products/product/summaryReducer';
import axiosInstance from '../../components/axios/axiosApi';
import CobotList from './CobotList';
import DeltaList from './DeltaList';
import ScaraList from './ScaraList';
import TablaList from './TablaList';

const MarginDivider = withStyles({
    root: {
        marginTop: 20,
        marginBottom: 20
    },
})(Divider);

const useStyles = makeStyles({
    table: {
        minWidth: 300,
    },
    cell: {
        padding: 8
    }
  });

function createData(name, calories) {
return { name, calories};
}

const rows = [
    createData('Coint 100', 2),
    createData('Coint 50', 2),
    createData('Coint 20', 2),
    createData('Taban 100', 1),
    createData('Eklenti 1', 1),
    createData('Eklenti 2', 1),
    createData('L Case 100', 2),
    createData('L Case 50', 2),
    createData('L Case 20', 2),
];

function Liste(props) {

    const { t, } = useTranslation();
    const dispatch = useDispatch();
    const MUIClasses = useStyles();
    const {
        sistem,
        eksen,
        eklem1,
        eklem2,
        eklem3,
        eklem4,
        eklem5,
        eklem6
    } = useSelector(state => state.module);

    const {
        fiyat
    } = useSelector(state => state.summary);

    const {
        scaraKol1,
        scaraKol2,
        scaraAyak,
        scaraPano,
    } = useSelector(state => state.scara);

    const {
        tabla,
        tablaAyak,
        tablaPano,
        tablaKasa
    } = useSelector(state => state.tabla);

    const {
        cobotAyak,
        cobotBoru1,
        cobotBoru2,
        cobotPano
    } = useSelector(state => state.cobot);

    const {
        deltaKol,
        deltaSac,
        deltaPanel,
        deltaPano
    } = useSelector(state => state.delta);

    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'});
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    const willMount = useRef(true);
      if (willMount.current) {
        getOutput();
        willMount.current = false;
      }

    const handleChange = (newValue) => {
        dispatch(changeStep({stepNumber: newValue}));
      };

    async function getOutput() {
        let cointArray;
        if (eksen === '1') {
            cointArray = `[${eklem1}]`;
        } else if (eksen === '2') {
            cointArray = `[${eklem1}|${eklem2}]`;
        } else if (eksen === '3') {
            cointArray = `[${eklem1}|${eklem2}|${eklem3}]`;
        } else if (eksen === '4') {
            cointArray = `[${eklem1}|${eklem2}|${eklem3}|${eklem4}]`;
        } else if (eksen === '5') {
            cointArray = `[${eklem1}|${eklem2}|${eklem3}|${eklem4}|${eklem5}]`;
        } else if (eksen === '6') {
            cointArray = `[${eklem1}|${eklem2}|${eklem3}|${eklem4}|${eklem5}|${eklem6}]`;
        }

        if (sistem === "delta") {
            await axiosInstance.get(`delta_robot?coint=${cointArray}&kol=[${deltaKol}]&sac=[${deltaSac}]&panel=[${deltaPanel}]&pano=[${deltaPano}]&format=json`)
                .then((response) => {
                    dispatch(changeErisim({erisim: response.data.erisim_alani}));
                    dispatch(changeKuvvet({kuvvet: response.data.uc_kuvveti}));
                    dispatch(changeFiyat({fiyat: response.data.fiyat}));
                })
                .catch((error) => console.log(error));
        } else if (sistem === 'cobot') {
            await axiosInstance.get(`cok_eksenli?coint=${cointArray}&ayak=[${cobotAyak}]&boru=[${cobotBoru1}|${cobotBoru2}]&pano=[${cobotPano}]&format=json`)
                .then((response) => {
                    dispatch(changeErisim({erisim: response.data.erisim_alani}));
                    dispatch(changeKuvvet({kuvvet: response.data.uc_kuvveti}));
                    dispatch(changeFiyat({fiyat: response.data.fiyat}));
                })
                .catch((error) => console.log(error));
        } else if (sistem === 'scara') {
            await axiosInstance.get(`scara_robot?coint=${cointArray}&kol=[${scaraKol1}|${scaraKol2}]&ayak=[${scaraAyak}]&pano=[${scaraPano}]&format=json`)
                .then((response) => {
                    dispatch(changeErisim({erisim: response.data.erisim_alani}));
                    dispatch(changeKuvvet({kuvvet: response.data.uc_kuvveti}));
                    dispatch(changeFiyat({fiyat: response.data.fiyat}));
                })
                .catch((error) => console.log(error));
        } else if (sistem === 'tabla') {
            await axiosInstance.get(`doner_tabla?coint=${cointArray}&ayak=[${cobotAyak}]&tabla=[${tabla}]&ayak=[${tablaAyak}]&kasa=[${tablaKasa}]&pano=[${tablaPano}]&format=json`)
                .then((response) => {
                    dispatch(changeErisim({erisim: response.data.erisim_alani}));
                    dispatch(changeKuvvet({kuvvet: response.data.uc_kuvveti}));
                    dispatch(changeFiyat({fiyat: response.data.fiyat}));
                })
                .catch((error) => console.log(error));
        }
    }

    return (
        <>
            <div className={classes.leftWrapper}>
                {/* {sistem === "tabla" && eksen === "1" && <img style={{objectFit: 'contain', width: '100%'}} src="/images/tek_tabla.png" alt="Tek Eksenli Döner Tabla" />}
                {sistem === "tabla" && eksen === "2" && <img style={{objectFit: 'contain', width: '100%'}} src="/images/iki_tabla.png" alt="İki Eksenli Döner Tabla" />}
                {sistem === "scara" && <img style={{objectFit: 'contain', width: '100%'}} src="/images/scara.png" alt="Scara Robot" />}
                {sistem === "delta" && <img style={{objectFit: 'contain', width: '100%'}} src="/images/delta.png" alt="Delta Robot" />}
                {sistem === "cobot" && <img style={{objectFit: 'contain', width: '100%'}} src="/images/alti_cobot.png" alt="Altı Eksenli Cobot" />} */}
                <img className={classes.imageItem} src="/images/boyutlar.PNG" alt="Altı Eksenli Cobot" />
            </div>
            <div className={classes.rightWrapper}>
                <div className={classes.rightWrapperlv1}>
                    <div className={classes.rightWrapperlv2}>
                        <div className={classes.title}>
                            <h2 className={classes.titleh2}>{t('SistemPage.productList')}</h2>
                            {sistem === "cobot" && <CobotList />}
                            {sistem === "delta" && <DeltaList />}
                            {sistem === "scara" && <ScaraList />}
                            {sistem === "tabla" && <TablaList />}
                        </div>
                    </div>
                    <div className={classes.formwizard}>
                        <div className={classes.formwizardlv1}>
                            <div className={classes.buttonContainer}>
                                <button onClick={() => handleChange(1)} className={classes.backButton}>
                                    <div className={classes.backButtonIcon}>
                                        <IconContext.Provider value={{ color: '#ffffff', size: 16 }}>
                                        <AiOutlineArrowRight />
                                        </IconContext.Provider>
                                    </div>
                                    <span className={classes.startText}>{t('Buton.geri')}</span>
                                </button>
                                <button onClick={() => handleChange(3)} className={classes.nextButton}>
                                    <span className={classes.startText}>{t('Buton.ileri')}</span>
                                    <div className={classes.buttonIcon}>
                                        <IconContext.Provider value={{ color: '#ffffff', size: 16 }}>
                                        <AiOutlineArrowRight />
                                        </IconContext.Provider>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Liste;