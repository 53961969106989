import React, { useEffect, useRef } from 'react';
import ReactGA from 'react-ga';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import psl from 'psl';
import axiosInstance from './components/axios/axiosApi';
//import createHistory from 'history/createBrowserHistory';
import PageWrapper from './pages/PageWrapper/PageWrapper';
import { useDispatch, useSelector } from 'react-redux';

// ------- GOOGLE ANALYTICS --------- //

//const history = createHistory()
ReactGA.initialize('UA-154450140-5', {
  debug: false
});
/* history.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search);
    console.log(location.pathname)
}); */

/* const usePageTracking = () => {
  let location = useLocation();

  useEffect(() => {
    ReactGA.initialize("UA-215425665-2");
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
};
 */

 // ------------------------------ //


function App() {

  /* useEffect(() => {
    let didCancel = false;

    async function axiosGet() {

        let username, password;

        if (!Boolean(localStorage.getItem('refresh')) || localStorage.getItem('refresh') === 'undefined') {

          username = 'Elf_Fiz.668_xxx';
          password = 'zolmnRWzjm2ETQ';
  
          axiosInstance.post('/token/', {
            username: username,
            password: password
          } )
          .then(response => {
              //console.log(response.data);
  
              if (response.data['access']) {
              localStorage.setItem('access', response.data.access);
              localStorage.setItem('refresh', response.data.refresh);
              //console.log('refreshToken', response.data.refresh);
              }
              
              return response.data['access'];
          })
          .catch(error => {
            console.log(error);
            console.log(error.response);
          });
        }

        if (!didCancel) { // Ignore if we started fetching something else
            //console.log(response);
        }
    }

    axiosGet();

    return () => { didCancel = true; }; // Remember if we start fetching something else

  }, []) */

  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<PageWrapper />} />
      </Routes>
    </Router>
  );
}

export default App;
