import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  scaraKol1: 1,
  scaraKol2: 1,
  scaraAyak: 100,
  scaraPano: 2,
};

export const scaraSlice = createSlice({
  name: 'scara',
  initialState,
  reducers: {
    changeScaraKol1: (state, action) => {
      state.scaraKol1 = action.payload.scaraKol1;
    },
    changeScaraKol2: (state, action) => {
      state.scaraKol2 = action.payload.scaraKol2;
    },
    changeScaraAyak: (state, action) => {
      state.scaraAyak = action.payload.scaraAyak;
    },
    changeScaraPano: (state, action) => {
      state.scaraPano = action.payload.scaraPano;
    },
  }
})

// Action creators are generated for each case reducer function
export const {
  changeScaraKol1,
  changeScaraKol2,
  changeScaraAyak,
  changeScaraPano
} = scaraSlice.actions

export default scaraSlice.reducer