import React, { useState, useRef, useEffect } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import classes from '../DrawingPage/DrawingPage.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { changeStep } from '../../services/products/product/stepReducer';
import { useMediaQuery } from 'react-responsive';
import Divider from '@material-ui/core/Divider';
import { makeStyles, withStyles, alpha } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import { changeListeFiyatı} from '../../services/products/product/summaryReducer';
import { changeAyak100, changeAyak20, changeAyak50, changeBoru200, changeBoru400, changeBoru600, changeCoint100, changeCoint20, changeCoint50, changeKasa100, changeKasa20, changeKasa50, changePano3 } from '../../services/products/product/listeReducer';
import { changeCheckoutAyak100, changeCheckoutAyak20, changeCheckoutAyak50, changeCheckoutBoru200, changeCheckoutBoru400, changeCheckoutBoru600, changeCheckoutCoint100, changeCheckoutCoint20, changeCheckoutCoint50, changeCheckoutDeltaKol1, changeCheckoutDeltaKol2, changeCheckoutDeltaPanel1, changeCheckoutDeltaPanel2, changeCheckoutKasa100, changeCheckoutKasa20, changeCheckoutKasa50, changeCheckoutPano1, changeCheckoutPano3, changeCheckoutScaraKol1, changeCheckoutScaraKol2, changeCheckoutTabanSacı1, changeCheckoutTabanSacı2, changeCheckoutTabla1, changeCheckoutTabla2 } from '../../services/products/product/checkoutReducer';

const MarginDivider = withStyles({
    root: {
        marginTop: 20,
        marginBottom: 20
    },
})(Divider);

const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
      width: '70px'
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: '1px solid #ced4da',
      fontSize: 16,
      width: 'auto',
      padding: '8px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }))(InputBase);

const useStyles = makeStyles({
    table: {
        minWidth: 300,
    },
    cell: {
        padding: 8
    },
    tablePrice: {
        minWidth: 300,
        marginTop: 10
    }
  });

function Liste(props) {

    const { t, } = useTranslation();
    const dispatch = useDispatch();
    const MUIClasses = useStyles();
    const {
        eksen,
        eklem1,
        eklem2,
        eklem3,
        eklem4,
        eklem5,
        eklem6
    } = useSelector(state => state.module);

    const {
        coint20,
        coint50,
        coint100,
        ayak20,
        ayak50,
        ayak100,
        kasa20,
        kasa50,
        kasa100,
        boru200,
        boru400,
        boru600,
        pano3
    } = useSelector(state => state.list);

    const {
        priceCoint20,
        priceCoint50,
        priceCoint100,
        priceAyak20,
        priceAyak50,
        priceAyak100,
        priceBoru200,
        priceBoru400,
        priceBoru600,
        priceKasa20,
        priceKasa50,
        priceKasa100,
        pricePano3,
    } = useSelector(state => state.price);

    const {
        checkoutCoint20,
        checkoutCoint50,
        checkoutCoint100,
        checkoutAyak20,
        checkoutAyak50,
        checkoutAyak100,
        checkoutKasa20,
        checkoutKasa50,
        checkoutKasa100,
        checkoutBoru200,
        checkoutBoru400,
        checkoutBoru600,
        checkoutPano3
    } = useSelector(state => state.checkout);

    const {
        fiyat,
        listeFiyatı
    } = useSelector(state => state.summary);

    const {
        cobotAyak,
        cobotBoru1,
        cobotBoru2,
        cobotKasa2,
        cobotKasa3,
        cobotKasa4,
        cobotKasa5,
        cobotKasa6
    } = useSelector(state => state.cobot);

    const [priceChanges, setPriceChanges] = useState({});

    //const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'});
    //const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    const willMount = useRef(true);
      if (willMount.current) {
        getList();
        willMount.current = false;
      }

    useEffect(() => {
        let didCancel = false;

        async function getPrice() {
    
            setPriceChanges({
                coint20: {
                    isim: 'Coint-20',
                    adet: Number(checkoutCoint20) - Number(coint20),
                    fiyat: priceCoint20 * (Number(checkoutCoint20) - Number(coint20))
                },
                coint50: {
                    isim: 'Coint-50',
                    adet: Number(checkoutCoint50) - Number(coint50),
                    fiyat: priceCoint50 * (Number(checkoutCoint50) - Number(coint50))
                },
                coint100: {
                    isim: 'Coint-100',
                    adet: Number(checkoutCoint100) - Number(coint100),
                    fiyat: priceCoint100 * (Number(checkoutCoint100) - Number(coint100))
                },
                ayak20: {
                    isim: 'Base-20',
                    adet: Number(checkoutAyak20) - Number(ayak20),
                    fiyat: priceAyak20 * (Number(checkoutAyak20) - Number(ayak20))
                },
                ayak50: {
                    isim: 'Base-50',
                    adet: Number(checkoutAyak50) - Number(ayak50),
                    fiyat: priceAyak50 * (Number(checkoutAyak50) - Number(ayak50))
                },
                ayak100: {
                    isim: 'Base-100',
                    adet: Number(checkoutAyak100) - Number(ayak100),
                    fiyat: priceAyak100 * (Number(checkoutAyak100) - Number(ayak100))
                },
                kasa20: {
                    isim: 'Case-20',
                    adet: Number(checkoutKasa20) - Number(kasa20),
                    fiyat: priceKasa20 * (Number(checkoutKasa20) - Number(kasa20))
                },
                kasa50: {
                    isim: 'Case-50',
                    adet: Number(checkoutKasa50) - Number(kasa50),
                    fiyat: priceKasa50 * (Number(checkoutKasa50) - Number(kasa50))
                },
                kasa100: {
                    isim: 'Case-100',
                    adet: Number(checkoutKasa100) - Number(kasa100),
                    fiyat: priceKasa100 * (Number(checkoutKasa100) - Number(kasa100))
                },
                boru200: {
                    isim: 'Extension-200',
                    adet: Number(checkoutBoru200) - Number(boru200),
                    fiyat: priceBoru200 * (Number(checkoutBoru200) - Number(boru200))
                },
                boru400: {
                    isim: 'Extension-400',
                    adet: Number(checkoutBoru400) - Number(boru400),
                    fiyat: priceBoru400 * (Number(checkoutBoru400) - Number(boru400))
                },
                boru600: {
                    isim: 'Extension-600',
                    adet: Number(checkoutBoru600) - Number(boru600),
                    fiyat: priceBoru600 * (Number(checkoutBoru600) - Number(boru600))
                },
                pano3: {
                    isim: 'Cabinet-3',
                    adet: Number(checkoutPano3) - Number(pano3),
                    fiyat: pricePano3 * (Number(checkoutPano3) - Number(pano3))
                },
            })
        }

        getPrice();

        return () => { didCancel = true; }; // Remember if we start fetching something else

    }, [checkoutCoint20, checkoutCoint50, checkoutCoint100, checkoutAyak20, checkoutAyak50, checkoutAyak100, checkoutKasa20, checkoutKasa50, checkoutKasa100, checkoutBoru200, checkoutBoru400, checkoutBoru600, checkoutPano3])

    useEffect(() => {
        let didCancel = false;

        async function getPrice() {
            let tempFiyat = 0;
            Object.entries(priceChanges).filter(([key, val]) => Number(val.adet) !== 0).map(([key,row]) => {
                tempFiyat += row.fiyat;
            }); 
            dispatch(changeListeFiyatı({listeFiyatı: Number(fiyat.replace('.','')) + tempFiyat}))
        }

        getPrice();

        return () => { didCancel = true; }; // Remember if we start fetching something else

    }, [priceChanges])

    const handleChange = (event) => {
        if (event.target.name === "Coint-20") {
            dispatch(changeCheckoutCoint20({checkoutCoint20: event.target.value}));
        } else if (event.target.name === "Coint-50") {
            dispatch(changeCheckoutCoint50({checkoutCoint50: event.target.value}));
        } else if (event.target.name === "Coint-100") {
            dispatch(changeCheckoutCoint100({checkoutCoint100: event.target.value}));
        } else if (event.target.name === "Base-20") {
            dispatch(changeCheckoutAyak20({checkoutAyak20: event.target.value}));
        } else if (event.target.name === "Base-50") {
            dispatch(changeCheckoutAyak50({checkoutAyak50: event.target.value}));
        } else if (event.target.name === "Base-100") {
            dispatch(changeCheckoutAyak100({checkoutAyak100: event.target.value}));
        } else if (event.target.name === "Case-20") {
            dispatch(changeCheckoutKasa20({checkoutKasa20: event.target.value}));
        } else if (event.target.name === "Case-50") {
            dispatch(changeCheckoutKasa50({checkoutKasa50: event.target.value}));
        } else if (event.target.name === "Case-100") {
            dispatch(changeCheckoutKasa100({checkoutKasa100: event.target.value}));
        } else if (event.target.name === "Extension-200") {
            dispatch(changeCheckoutBoru200({checkoutBoru200: event.target.value}));
        } else if (event.target.name === "Extension-400") {
            dispatch(changeCheckoutBoru400({checkoutBoru400: event.target.value}));
        } else if (event.target.name === "Extension-600") {
            dispatch(changeCheckoutBoru600({checkoutBoru600: event.target.value}));
        } else if (event.target.name === "Cabinet-3") {
            dispatch(changeCheckoutPano3({checkoutPano3: event.target.value}));
        }
      };

    function createData(name, pieces, listPrice) {
    return { name, pieces, listPrice};
    }
    
    const rows = [
        createData('Coint-20', checkoutCoint20, priceCoint20),
        createData('Coint-50', checkoutCoint50, priceCoint50),
        createData('Coint-100', checkoutCoint100, priceCoint100),
        createData('Base-20', checkoutAyak20, priceAyak20),
        createData('Base-50', checkoutAyak50, priceAyak50),
        createData('Base-100', checkoutAyak100, priceAyak100),
        createData('Case-20', checkoutKasa20, priceKasa20),
        createData('Case-50', checkoutKasa50, priceKasa50),
        createData('Case-100', checkoutKasa100, priceKasa100),
        createData('Extension-200', checkoutBoru200, priceBoru200),
        createData('Extension-400', checkoutBoru400, priceBoru400),
        createData('Extension-600', checkoutBoru600, priceBoru600),
        createData('Cabinet-3', checkoutPano3, pricePano3),
    ];

    function getList() {

        // Cobot Coint
        let tempCoint20 = 0;
        let tempCoint50 = 0;
        let tempCoint100 = 0;
        if (eksen === '4') {
            [eklem1, eklem2, eklem3, eklem4].forEach((eklem) => {
                if (eklem === 20) {
                    tempCoint20 += 1;
                } else if (eklem === 50) {
                    tempCoint50 += 1;
                } else if (eklem === 100) {
                    tempCoint100 += 1;
                }
            })
        } else if (eksen === '6') {
            [eklem1, eklem2, eklem3, eklem4, eklem5, eklem6].forEach((eklem) => {
                if (eklem === 20) {
                    tempCoint20 += 1;
                } else if (eklem === 50) {
                    tempCoint50 += 1;
                } else if (eklem === 100) {
                    tempCoint100 += 1;
                }
            })
        }

        // Cobot Kasa
        let tempKasa20 = 0;
        let tempKasa50 = 0;
        let tempKasa100 = 0;
        if (eksen === '4') {
            [cobotKasa2, cobotKasa3, cobotKasa4].forEach((kasa) => {
                if (kasa === 20) {
                    tempKasa20 += 1;
                } else if (kasa === 50) {
                    tempKasa50 += 1;
                } else if (kasa === 100) {
                    tempKasa100 += 1;
                }
            })
        } else if (eksen === '6') {
            [cobotKasa2, cobotKasa3, cobotKasa4, cobotKasa5, cobotKasa6].forEach((kasa) => {
                if (kasa === 20) {
                    tempKasa20 += 1;
                } else if (kasa === 50) {
                    tempKasa50 += 1;
                } else if (kasa === 100) {
                    tempKasa100 += 1;
                }
            })
        }

        // Cobot Boru
        let tempBoru200 = 0;
        let tempBoru400 = 0;
        let tempBoru600 = 0;
        if (eksen === '4') {
            [cobotBoru1, cobotBoru2].forEach((boru) => {
                if (boru === 200) {
                    tempBoru200 += 1;
                } else if (boru === 400) {
                    tempBoru400 += 1;
                } else if (boru === 600) {
                    tempBoru600 += 1;
                }
            })
        } else if (eksen === '6') {
            [cobotBoru1, cobotBoru2].forEach((boru) => {
                if (boru === 200) {
                    tempBoru200 += 1;
                } else if (boru === 400) {
                    tempBoru400 += 1;
                } else if (boru === 600) {
                    tempBoru600 += 1;
                }
            })
        }

        // Cobot Ayak
        let tempCobotAyak20 = 0;
        let tempCobotAyak50 = 0;
        let tempCobotAyak100 = 0;
        if (cobotAyak === 20) {
            tempCobotAyak20 += 1;
        } else if (cobotAyak === 50) {
            tempCobotAyak50 += 1;
        } else if (cobotAyak === 100) {
            tempCobotAyak100 += 1;
        }
        
        // State değişimleri
        dispatch(changeCoint20({coint20: tempCoint20}));
        dispatch(changeCoint50({coint50: tempCoint50}));
        dispatch(changeCoint100({coint100: tempCoint100}));
    
        dispatch(changeKasa20({kasa20: tempKasa20}));
        dispatch(changeKasa50({kasa50: tempKasa50}));
        dispatch(changeKasa100({kasa100: tempKasa100}));

        dispatch(changeBoru200({boru200: tempBoru200}));
        dispatch(changeBoru400({boru400: tempBoru400}));
        dispatch(changeBoru600({boru600: tempBoru600}));

        dispatch(changeAyak20({ayak20: tempCobotAyak20}));
        dispatch(changeAyak50({ayak50: tempCobotAyak50}));
        dispatch(changeAyak100({ayak100: tempCobotAyak100}));

        // checkout
        dispatch(changeCheckoutCoint20({checkoutCoint20: tempCoint20}));
        dispatch(changeCheckoutCoint50({checkoutCoint50: tempCoint50}));
        dispatch(changeCheckoutCoint100({checkoutCoint100: tempCoint100}));
    
        dispatch(changeCheckoutKasa20({checkoutKasa20: tempKasa20}));
        dispatch(changeCheckoutKasa50({checkoutKasa50: tempKasa50}));
        dispatch(changeCheckoutKasa100({checkoutKasa100: tempKasa100}));

        dispatch(changeCheckoutBoru200({checkoutBoru200: tempBoru200}));
        dispatch(changeCheckoutBoru400({checkoutBoru400: tempBoru400}));
        dispatch(changeCheckoutBoru600({checkoutBoru600: tempBoru600}));

        dispatch(changeCheckoutAyak20({checkoutAyak20: tempCobotAyak20}));
        dispatch(changeCheckoutAyak50({checkoutAyak50: tempCobotAyak50}));
        dispatch(changeCheckoutAyak100({checkoutAyak100: tempCobotAyak100}));
    }

    return (
        <>
            <div>
                <TableContainer>
                    <Table className={MUIClasses.table} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell className={MUIClasses.cell}>{t('SistemPage.productName')}</TableCell>
                            <TableCell className={MUIClasses.cell} align="right">{t('SistemPage.quantity')}</TableCell>
                            <TableCell className={MUIClasses.cell} align="right">{t('SistemPage.unitPrice')}</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {rows.filter(row => row.pieces !== 0).map((row) => (
                            <TableRow key={row.name}>
                            <TableCell className={MUIClasses.cell} component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell className={MUIClasses.cell} align="right">
                            <FormControl>
                                <BootstrapInput
                                    name={row.name}
                                    type="number"
                                    //defaultValue={row.pieces}
                                    value={row.pieces}
                                    id="bootstrap-input"
                                    inputProps={{  min: 0, max: 8, step: 1 }}
                                    onChange={handleChange}
                                    />
                            </FormControl>
                            </TableCell>
                            <TableCell className={MUIClasses.cell} align="right">
                                {row.listPrice} €
                            </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <h4 className={classes.titleh2} style={{ marginTop: '0.75rem', fontSize: '1.1rem', textAlign: 'end' }}>{t('SistemPage.fixedConfigurationPrice')} {fiyat.replace('.','')} €</h4>
                <TableContainer>
                    <Table className={MUIClasses.tablePrice} aria-label="simple table">
                        <TableBody>
                        {(Object.entries(priceChanges)).filter(([key, val]) => Number(val.adet) !== 0).map(([key,row]) => (
                            Boolean(Number(row.adet)) &&
                            <TableRow key={row.isim}>
                            <TableCell className={MUIClasses.cell} component="th" scope="row">
                                {row.isim}
                            </TableCell>
                            <TableCell className={MUIClasses.cell} align="right">
                                {(row.adet)>0 ? `+${row.adet}` : `${row.adet}`}x
                            </TableCell>
                            <TableCell className={MUIClasses.cell} align="right">
                                {(row.fiyat)>0 ? `+${row.fiyat}`: `${row.fiyat}`} €
                            </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            {(Number(listeFiyatı) !== Number(fiyat.replace('.',''))) && <h4 className={classes.titleh2} style={{ marginTop: '0.75rem', fontSize: '1.1rem', textAlign: 'end' }}>{t('SistemPage.listPrice')} {listeFiyatı} €</h4>}
        </>
    );
}

export default Liste;