import React, { useState, useRef, useEffect } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import classes from '../DrawingPage/DrawingPage.module.css';
import { IconContext } from 'react-icons/lib';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';
import { changeStep } from '../../services/products/product/stepReducer';
import { useMediaQuery } from 'react-responsive';
import Divider from '@material-ui/core/Divider';
import { makeStyles, withStyles, alpha } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import TableSelect from './TableSelect';
import { changeListeFiyatı } from '../../services/products/product/summaryReducer';
import axiosInstance from '../../components/axios/axiosApi';
import { changeAyak100, changeAyak20, changeAyak50, changeBoru200, changeBoru400, changeBoru600, changeCoint100, changeCoint20, changeCoint50, changeDeltaKol1, changeDeltaKol2, changeDeltaPanel1, changeDeltaPanel2, changeKasa100, changeKasa20, changeKasa50, changePano3, changeTabanSacı1, changeTabanSacı2, changePano2 } from '../../services/products/product/listeReducer';
import {
    changeCheckoutCoint20,
    changeCheckoutCoint50,
    changeCheckoutCoint100,
    changeCheckoutAyak20,
    changeCheckoutAyak50,
    changeCheckoutAyak100,
    changeCheckoutBoru200,
    changeCheckoutBoru400,
    changeCheckoutBoru600,
    changeCheckoutTabanSacı1,
    changeCheckoutTabanSacı2,
    changeCheckoutDeltaKol1,
    changeCheckoutDeltaKol2,
    changeCheckoutDeltaPanel1,
    changeCheckoutDeltaPanel2,
    changeCheckoutTabla1,
    changeCheckoutTabla2,
    changeCheckoutKasa20,
    changeCheckoutKasa50,
    changeCheckoutKasa100,
    changeCheckoutScaraKol1,
    changeCheckoutScaraKol2,
    changeCheckoutPano1,
    changeCheckoutPano2,
    changeCheckoutPano3 
} from '../../services/products/product/checkoutReducer';

const MarginDivider = withStyles({
    root: {
        marginTop: 20,
        marginBottom: 20
    },
})(Divider);

const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
      width: '70px'
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: '1px solid #ced4da',
      fontSize: 16,
      width: 'auto',
      padding: '8px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }))(InputBase);

const useStyles = makeStyles({
    table: {
        minWidth: 300,
    },
    cell: {
        padding: 8
    },
    tablePrice: {
        minWidth: 300,
        marginTop: 10
    }
  });

function DeltaList(props) {

    const { t, } = useTranslation();
    const dispatch = useDispatch();
    const MUIClasses = useStyles();
    const {
        eklem1,
        eklem2,
        eklem3,
    } = useSelector(state => state.module);

    const {
        coint20,
        coint50,
        coint100,
        kasa20,
        kasa50,
        kasa100,
        pano2,
        deltaKol1,
        deltaKol2,
        deltaPanel1,
        deltaPanel2,
        tabanSacı1,
        tabanSacı2
    } = useSelector(state => state.list);

    const {
        priceCoint20,
        priceCoint50,
        priceCoint100,
        priceKasa20,
        priceKasa50,
        priceKasa100,
        pricePano2,
        priceDeltaKol1,
        priceDeltaKol2,
        priceDeltaPanel1,
        priceDeltaPanel2,
        priceTabanSacı1,
        priceTabanSacı2
    } = useSelector(state => state.price);

    const {
        checkoutCoint20,
        checkoutCoint50,
        checkoutCoint100,
        checkoutKasa20,
        checkoutKasa50,
        checkoutKasa100,
        checkoutPano2,
        checkoutDeltaKol1,
        checkoutDeltaKol2,
        checkoutDeltaPanel1,
        checkoutDeltaPanel2,
        checkoutTabanSacı1,
        checkoutTabanSacı2
    } = useSelector(state => state.checkout);

    const {
        fiyat,
        listeFiyatı
    } = useSelector(state => state.summary);

    const {
        deltaKasa1,
        deltaKasa2,
        deltaKasa3,
        deltaKol,
        deltaSac,
        deltaPanel,
    } = useSelector(state => state.delta);

    const [priceChanges, setPriceChanges] = useState({});

    //const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'});
    //const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    const willMount = useRef(true);
      if (willMount.current) {
        getList();
        willMount.current = false;
      }

    useEffect(() => {
        let didCancel = false;

        async function getPrice() {
    
            setPriceChanges({
                coint20: {
                    isim: 'Coint-20',
                    adet: Number(checkoutCoint20) - Number(coint20),
                    fiyat: priceCoint20 * (Number(checkoutCoint20) - Number(coint20))
                },
                coint50: {
                    isim: 'Coint-50',
                    adet: Number(checkoutCoint50) - Number(coint50),
                    fiyat: priceCoint50 * (Number(checkoutCoint50) - Number(coint50))
                },
                coint100: {
                    isim: 'Coint-100',
                    adet: Number(checkoutCoint100) - Number(coint100),
                    fiyat: priceCoint100 * (Number(checkoutCoint100) - Number(coint100))
                },
                kasa20: {
                    isim: 'Case-20',
                    adet: Number(checkoutKasa20) - Number(kasa20),
                    fiyat: priceKasa20 * (Number(checkoutKasa20) - Number(kasa20))
                },
                kasa50: {
                    isim: 'Case-50',
                    adet: Number(checkoutKasa50) - Number(kasa50),
                    fiyat: priceKasa50 * (Number(checkoutKasa50) - Number(kasa50))
                },
                kasa100: {
                    isim: 'Case-100',
                    adet: Number(checkoutKasa100) - Number(kasa100),
                    fiyat: priceKasa100 * (Number(checkoutKasa100) - Number(kasa100))
                },
                deltaKol1: {
                    isim: 'Delta Arm 1',
                    adet: Number(checkoutDeltaKol1) - Number(deltaKol1),
                    fiyat: priceDeltaKol1 * (Number(checkoutDeltaKol1) - Number(deltaKol1))
                },
                deltaKol2: {
                    isim: 'Delta Arm 2',
                    adet: Number(checkoutDeltaKol2) - Number(deltaKol2),
                    fiyat: priceDeltaKol2 * (Number(checkoutDeltaKol2) - Number(deltaKol2))
                },
                deltaPanel1: {
                    isim: 'Delta Panel 1',
                    adet: Number(checkoutDeltaPanel1) - Number(deltaPanel1),
                    fiyat: priceDeltaPanel1 * (Number(checkoutDeltaPanel1) - Number(deltaPanel1))
                },
                deltaPanel2: {
                    isim: 'Delta Panel 2',
                    adet: Number(checkoutDeltaPanel2) - Number(deltaPanel2),
                    fiyat: priceDeltaPanel2 * (Number(checkoutDeltaPanel2) - Number(deltaPanel2))
                },
                tabanSacı1: {
                    isim: 'Floor Sheet 1',
                    adet: Number(checkoutTabanSacı1) - Number(tabanSacı1),
                    fiyat: priceTabanSacı1 * (Number(checkoutTabanSacı1) - Number(tabanSacı1))
                },
                tabanSacı2: {
                    isim: 'Floor Sheet 2',
                    adet: Number(checkoutTabanSacı2) - Number(tabanSacı2),
                    fiyat: priceTabanSacı2 * (Number(checkoutTabanSacı2) - Number(tabanSacı2))
                },
                pano2: {
                    isim: 'Cabinet-1',
                    adet: Number(checkoutPano2) - Number(pano2),
                    fiyat: pricePano2 * (Number(checkoutPano2) - Number(pano2))
                },
            })            
        }

        getPrice();

        return () => { didCancel = true; }; // Remember if we start fetching something else

    }, [checkoutCoint20, checkoutCoint50, checkoutCoint100, checkoutKasa20, checkoutKasa50, checkoutKasa100, checkoutDeltaKol1, checkoutDeltaKol2, checkoutDeltaPanel1, checkoutDeltaPanel2, checkoutTabanSacı1, checkoutTabanSacı2, checkoutPano2])

    useEffect(() => {
        let didCancel = false;

        async function getPrice() {
            let tempFiyat = 0;
            Object.entries(priceChanges).filter(([key, val]) => Number(val.adet) !== 0).map(([key,row]) => {
                tempFiyat += row.fiyat;
            }); 
            dispatch(changeListeFiyatı({listeFiyatı: Number(fiyat.replace('.','')) + tempFiyat}))
        }

        getPrice();

        return () => { didCancel = true; }; // Remember if we start fetching something else

    }, [priceChanges])

    const handleChange = (event) => {
        if (event.target.name === "Coint-20") {
            dispatch(changeCheckoutCoint20({checkoutCoint20: event.target.value}));
        } else if (event.target.name === "Coint-50") {
            dispatch(changeCheckoutCoint50({checkoutCoint50: event.target.value}));
        } else if (event.target.name === "Coint-100") {
            dispatch(changeCheckoutCoint100({checkoutCoint100: event.target.value}));
        } else if (event.target.name === "Case-20") {
            dispatch(changeCheckoutKasa20({checkoutKasa20: event.target.value}));
        } else if (event.target.name === "Case-50") {
            dispatch(changeCheckoutKasa50({checkoutKasa50: event.target.value}));
        } else if (event.target.name === "Case-100") {
            dispatch(changeCheckoutKasa100({checkoutKasa100: event.target.value}));
        } else if (event.target.name === "Delta Arm 1") {
            dispatch(changeCheckoutDeltaKol1({checkoutDeltaKol1: event.target.value}));
        } else if (event.target.name === "Delta Arm 2") {
            dispatch(changeCheckoutDeltaKol2({checkoutDeltaKol2: event.target.value}));
        } else if (event.target.name === "Delta Panel 1") {
            dispatch(changeCheckoutDeltaPanel1({checkoutDeltaPanel1: event.target.value}));
        } else if (event.target.name === "Delta Panel 2") {
            dispatch(changeCheckoutDeltaPanel2({checkoutDeltaPanel2: event.target.value}));
        } else if (event.target.name === "Floor Sheet 1") {
            dispatch(changeCheckoutTabanSacı1({checkoutTabanSacı1: event.target.value}));
        } else if (event.target.name === "Floor Sheet 2") {
            dispatch(changeCheckoutTabanSacı2({checkoutTabanSacı2: event.target.value}));
        } else if (event.target.name === "Cabinet-2") {
            dispatch(changeCheckoutPano2({checkoutPano2: event.target.value}));
        }
      };

    function createData(name, pieces, listPrice) {
        return { name, pieces, listPrice};
    }
    
    const rows = [
        createData('Coint-20', checkoutCoint20, priceCoint20),
        createData('Coint-50', checkoutCoint50, priceCoint50),
        createData('Coint-100', checkoutCoint100, priceCoint100),
        createData('Case-20', checkoutKasa20, priceKasa20),
        createData('Case-50', checkoutKasa50, priceKasa50),
        createData('Case-100', checkoutKasa100, priceKasa100),
        createData('Delta Arm 1', checkoutDeltaKol1, priceDeltaKol1),
        createData('Delta Arm 2', checkoutDeltaKol2, priceDeltaKol2),
        createData('Delta Panel 1', checkoutDeltaPanel1, priceDeltaKol1),
        createData('Delta Panel 2', checkoutDeltaPanel2, priceDeltaKol2),
        createData('Floor Sheet 1', checkoutTabanSacı1, priceTabanSacı1),
        createData('Floor Sheet 2', checkoutTabanSacı2, priceTabanSacı1),
        createData('Cabinet-2', checkoutPano2, pricePano2),
    ];

    function getList() {

        // Delta Coint
        let tempCoint20 = 0;
        let tempCoint50 = 0;
        let tempCoint100 = 0;
        [eklem1, eklem2, eklem3].forEach((eklem) => {
            if (eklem === 20) {
                tempCoint20 += 1;
            } else if (eklem === 50) {
                tempCoint50 += 1;
            } else if (eklem === 100) {
                tempCoint100 += 1;
            }
        })

        // Delta Kasa
        let tempKasa20 = 0;
        let tempKasa50 = 0;
        let tempKasa100 = 0;
        [deltaKasa1, deltaKasa2, deltaKasa3].forEach((kasa) => {
            if (kasa === 20) {
                tempKasa20 += 1;
            } else if (kasa === 50) {
                tempKasa50 += 1;
            } else if (kasa === 100) {
                tempKasa100 += 1;
            }
        })

        // Delta Kol
        let tempDeltaKol1 = 0;
        let tempDeltaKol2 = 0
        if (deltaKol === 1) {
            tempDeltaKol1 += 1; 
        } else if (deltaKol === 2) {
            tempDeltaKol2 += 1; 
        }

        // Delta Taban Sacı
        let tempDeltaSac1 = 0;
        let tempDeltaSac2 = 0;
        if (deltaSac === 1) {
            tempDeltaSac1 += 1;
        } else if (deltaSac === 2) {
            tempDeltaSac2 += 1;
        }

        // Delta Panel
        let tempDeltaPanel1 = 0;
        let tempDeltaPanel2 = 0;
        if (deltaPanel === 1) {
            tempDeltaPanel1 += 1;
        } else if (deltaPanel === 2) {
            tempDeltaPanel2 += 1;
        }
        
        // State değişimleri
        dispatch(changeCoint20({coint20: tempCoint20}));
        dispatch(changeCoint50({coint50: tempCoint50}));
        dispatch(changeCoint100({coint100: tempCoint100}));
    
        dispatch(changeKasa20({kasa20: tempKasa20}));
        dispatch(changeKasa50({kasa50: tempKasa50}));
        dispatch(changeKasa100({kasa100: tempKasa100}));

        dispatch(changeDeltaKol1({deltaKol1: tempDeltaKol1}));
        dispatch(changeDeltaKol2({deltaKol2: tempDeltaKol2}));

        dispatch(changeTabanSacı1({tabanSacı1: tempDeltaSac1}));
        dispatch(changeTabanSacı2({tabanSacı2: tempDeltaSac2}));

        dispatch(changeDeltaPanel1({deltaPanel1: tempDeltaPanel1}));
        dispatch(changeDeltaPanel2({deltaPanel2: tempDeltaPanel2}));

        // checkout
        dispatch(changeCheckoutCoint20({checkoutCoint20: tempCoint20}));
        dispatch(changeCheckoutCoint50({checkoutCoint50: tempCoint50}));
        dispatch(changeCheckoutCoint100({checkoutCoint100: tempCoint100}));

        dispatch(changeCheckoutKasa20({checkoutKasa20: tempKasa20}));
        dispatch(changeCheckoutKasa50({checkoutKasa50: tempKasa50}));
        dispatch(changeCheckoutKasa100({checkoutKasa100: tempKasa100}));

        dispatch(changeCheckoutDeltaKol1({checkoutDeltaKol1: tempDeltaKol1}));
        dispatch(changeCheckoutDeltaKol2({checkoutDeltaKol2: tempDeltaKol2}));

        dispatch(changeCheckoutTabanSacı1({checkoutTabanSacı1: tempDeltaSac1}));
        dispatch(changeCheckoutTabanSacı2({checkoutTabanSacı2: tempDeltaSac2}));

        dispatch(changeCheckoutDeltaPanel1({checkoutDeltaPanel1: tempDeltaPanel1}));
        dispatch(changeCheckoutDeltaPanel2({checkoutDeltaPanel2: tempDeltaPanel2}));
    }

    return (
        <>
            <div>
                <TableContainer>
                    <Table className={MUIClasses.table} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell className={MUIClasses.cell}>{t('SistemPage.productName')}</TableCell>
                            <TableCell className={MUIClasses.cell} align="right">{t('SistemPage.quantity')}</TableCell>
                            <TableCell className={MUIClasses.cell} align="right">{t('SistemPage.unitPrice')}</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {rows.filter(row => row.pieces !== 0).map((row) => (
                            <TableRow key={row.name}>
                            <TableCell className={MUIClasses.cell} component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell className={MUIClasses.cell} align="right">
                            <FormControl>
                                <BootstrapInput
                                    name={row.name}
                                    type="number"
                                    //defaultValue={row.pieces}
                                    value={row.pieces}
                                    id="bootstrap-input"
                                    inputProps={{  min: 0, max: 8, step: 1 }}
                                    onChange={handleChange}
                                    />
                            </FormControl>
                            </TableCell>
                            <TableCell className={MUIClasses.cell} align="right">
                                {row.listPrice} €
                            </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <h4 className={classes.titleh2} style={{ marginTop: '0.75rem', fontSize: '1.1rem', textAlign: 'end' }}>{t('SistemPage.fixedConfigurationPrice')} {fiyat.replace('.','')} €</h4>
                <TableContainer>
                    <Table className={MUIClasses.tablePrice} aria-label="simple table">
                        <TableBody>
                        {(Object.entries(priceChanges)).filter(([key, val]) => Number(val.adet) !== 0).map(([key,row]) => (
                            Boolean(Number(row.adet)) &&
                            <TableRow key={row.isim}>
                            <TableCell className={MUIClasses.cell} component="th" scope="row">
                                {row.isim}
                            </TableCell>
                            <TableCell className={MUIClasses.cell} align="right">
                                {(row.adet)>0 ? `+${row.adet}` : `${row.adet}`}x
                            </TableCell>
                            <TableCell className={MUIClasses.cell} align="right">
                                {(row.fiyat)>0 ? `+${row.fiyat}`: `${row.fiyat}`} €
                            </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            {(Number(listeFiyatı) !== Number(fiyat.replace('.',''))) && <h4 className={classes.titleh2} style={{ marginTop: '0.75rem', fontSize: '1.1rem', textAlign: 'end' }}>{t('SistemPage.listPrice')} {listeFiyatı} €</h4>}
        </>
    );
}

export default DeltaList;