import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  latitude: Number(localStorage.getItem('enlem')) || 38.7334154,
  longitude: Number(localStorage.getItem('boylam')) || 35.5101351,
  zoomLevel: 19,
  markerOn: false,
  roofArea: null,
  panelAmount: 0,
  mapStep: 1,
  tempLatitude: 38.7334154, //marker center sorununu çözmek için tanımladım.
  tempLongitude: 38.7334154,
  infoWindow: false,
  staticMapURL: null,
  drawPoints: null,
  cityName: localStorage.getItem('sehirIsmi') || null,
  plaka: localStorage.getItem('plaka') || null,
  ilce: localStorage.getItem('ilce') || null,
  validAdress: false,
  detailedAdress: localStorage.getItem('detailedAddress') || null,
  polygonPoints: null,
  alanTercihi: 'cizim',
  tabButonlarAktif: false,
  currentZoom: 19
};

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    changeLocation: (state, action) => {
      state.latitude = action.payload.latitude;
      state.longitude = action.payload.longitude;
      localStorage.setItem('enlem', action.payload.latitude);
      localStorage.setItem('boylam', action.payload.longitude);
    },
    changeMapStep: (state, action) => {
      state.mapStep = action.payload.mapStep;
    },
    changeTempLocation: (state, action) => {
      state.tempLatitude = action.payload.tempLatitude;
      state.tempLongitude = action.payload.tempLongitude;
    },
    changeZoom: (state, action) => {
      state.zoomLevel = action.payload.zoomLevel;
    },
    changeArea: (state, action) => {
      state.roofArea = action.payload.roofArea;
    },
    changeMarker: (state, action) => {
      state.markerOn = action.payload.markerOn;
    },
    changeInfoWindow: (state, action) => {
      state.infoWindow = action.payload.infoWindow;
    },
    changeStaticMapURL: (state, action) => {
      state.staticMapURL = action.payload.staticMapURL;
    },
    changeDrawPoints: (state, action) => {
      state.drawPoints = action.payload.drawPoints;
    },
    changeCityName: (state, action) => {
      state.cityName = action.payload.cityName;
      localStorage.setItem('sehirIsmi', action.payload.cityName);
    },
    changePlaka: (state, action) => {
      state.plaka = action.payload.plaka;
      localStorage.setItem('plaka', action.payload.plaka);
    },
    changeIlce: (state, action) => {
      state.ilce = action.payload.ilce;
      localStorage.setItem('ilce', action.payload.ilce);
    },
    changeAdressValidity: (state, action) => {
      state.validAdress = action.payload.validAdress;
    },
    changeDetailedAdress: (state, action) => {
      state.detailedAdress = action.payload.detailedAdress;
      localStorage.setItem('detailedAddress', action.payload.detailedAdress);
    },
    changePolygonPoints: (state, action) => {
      state.polygonPoints = action.payload.polygonPoints;
    },
    changePanelAmount: (state, action) => {
      state.panelAmount = action.payload.panelAmount;
    },
    changeAlanTercihi: (state, action) => {
      state.alanTercihi = action.payload.alanTercihi;
    },
    changeTabButonlarAktif: (state, action) => {
      state.tabButonlarAktif = action.payload.tabButonlarAktif;
    },
    changeCurrentZoom: (state, action) => {
      state.currentZoom = action.payload.currentZoom;
    },
    resetState: () => initialState,
  }
})

// Action creators are generated for each case reducer function
export const {
  changeLocation,
  changeMapStep,
  resetState,
  changeTempLocation,
  changeZoom,
  changeArea,
  changeMarker,
  changeInfoWindow,
  changeStaticMapURL,
  changeDrawPoints,
  changeCityName,
  changePlaka,
  changeIlce,
  changeAdressValidity,
  changeDetailedAdress,
  changePolygonPoints,
  changePanelAmount,
  changeAlanTercihi,
  changeTabButonlarAktif,
  changeCurrentZoom
} = locationSlice.actions

export default locationSlice.reducer