import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  deltaKol: 1,
  deltaSac: 1,
  deltaPanel: 1,
  deltaPano: 2,
  deltaKasa1: 100,
  deltaKasa2: 100,
  deltaKasa3: 50
};

export const deltaSlice = createSlice({
  name: 'delta',
  initialState,
  reducers: {
    changeKol: (state, action) => {
      state.deltaKol = action.payload.deltaKol;
    },
    changeSac: (state, action) => {
      state.deltaSac = action.payload.deltaSac;
    },
    changePanel: (state, action) => {
      state.deltaPanel = action.payload.deltaPanel;
    },
    changePano: (state, action) => {
        state.deltaPano = action.payload.deltaPano;
      },
    changeDeltaKasa1: (state, action) => {
        state.deltaKasa1 = action.payload.deltaKasa1;
      },
    changeDeltaKasa2: (state, action) => {
        state.deltaKasa2 = action.payload.deltaKasa2;
      },
    changeDeltaKasa3: (state, action) => {
        state.deltaKasa3 = action.payload.deltaKasa3;
      },
  }
})

// Action creators are generated for each case reducer function
export const {
    changeKol,
    changeSac,
    changePanel,
    changePano,
    changeDeltaKasa1,
    changeDeltaKasa2,
    changeDeltaKasa3
} = deltaSlice.actions

export default deltaSlice.reducer