import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    changeKol,
    changeSac,
    changePanel,
    changePano
} from '../../services/products/product/deltaReducer';
import classes from './DrawingPage.module.css';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 150,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }))(InputBase);

function DeltaSelects(props) {

    const { t, } = useTranslation();
    const dispatch = useDispatch();

    const MUIClasses = useStyles();

    const {
        deltaKol,
        deltaSac,
        deltaPanel,
        deltaPano
    } = useSelector(state => state.delta);

    const handleChangeSelect = (event) => {
        if (event.target.name === "kol") {
            dispatch(changeKol({deltaKol: event.target.value}));
        } else if (event.target.name === "sac") {
            dispatch(changeSac({deltaSac: event.target.value}));
        } else if (event.target.name === "panel") {
            dispatch(changePanel({deltaPanel: event.target.value}));
        } else if (event.target.name === "pano") {
            dispatch(changePano({deltaPano: event.target.value}));
        }
    };

    return (
        <>
            <div className={classes.alignItemsFlex}>
                <span className={classes.categorySpan}>Arm:</span>
                <FormControl variant="outlined" className={MUIClasses.formControl}>
                {/* <InputLabel id="demo-simple-select-outlined-label">Değer</InputLabel> */}
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={deltaKol}
                        displayEmpty
                        onChange={handleChangeSelect}
                        label="Değer"
                        notched
                        input={<BootstrapInput />}
                        name="kol"
                    >
                        <MenuItem value={1}>Delta Arm 1</MenuItem>
                        <MenuItem value={2}>Delta Arm 2</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div className={classes.alignItemsFlex}>
                <span className={classes.categorySpan}>Floor Sheet:</span>
                <FormControl variant="outlined" className={MUIClasses.formControl}>
                {/* <InputLabel id="demo-simple-select-outlined-label">Değer</InputLabel> */}
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={deltaSac}
                        displayEmpty
                        onChange={handleChangeSelect}
                        label="Değer"
                        notched
                        input={<BootstrapInput />}
                        name="sac"
                    >
                        <MenuItem value={1}>Floor Sheet 1</MenuItem>
                        <MenuItem value={2}>Floor Sheet 2</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div className={classes.alignItemsFlex}>
                <span className={classes.categorySpan}>Delta Panel:</span>
                <FormControl variant="outlined" className={MUIClasses.formControl}>
                {/* <InputLabel id="demo-simple-select-outlined-label">Değer</InputLabel> */}
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={deltaPanel}
                        displayEmpty
                        onChange={handleChangeSelect}
                        label="Değer"
                        notched
                        input={<BootstrapInput />}
                        name="panel"
                    >
                        <MenuItem value={1}>Delta Panel 1</MenuItem>
                        <MenuItem value={2}>Delta Panel 2</MenuItem>
                    </Select>
                </FormControl>
            </div>
        </>
    );
}

export default DeltaSelects;