import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import classes from './Navbar.module.css';
import { useMediaQuery } from 'react-responsive';
import { useSelector, useDispatch } from 'react-redux';
import { changeStep } from '../../services/products/product/stepReducer';
import {
    withStyles,
    Select,
    MenuItem,
    InputBase,
    Button
  } from '@material-ui/core';
import { FaUser } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { Link } from 'react-router-dom';

const StyledSelect = withStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
})(Select);

const StyledButtonMobile = withStyles({
    root: {
        backgroundColor: '#6d657f',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#4f4860'
        },
        padding: '6px',
        width: '40px',
        minWidth: '40px',
        height: '40px'
    }
})(Button);

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
        marginTop: theme.spacing(3),
        },
        marginRight: 30,
        backgroundColor: 'whitesmoke',
        borderRadius: 4,
        height: 40,
        alignSelf: 'center'
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: 'whitesmoke',
        // border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        // transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
        'Poppins',
        'sans-serif',
        ].join(','),
        '&:focus': {
        borderRadius: 4,
        // borderColor: '#80bdff',
        // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const BootstrapInputMobile = withStyles((theme) => ({
    root: {
        'label + &': {
        marginTop: theme.spacing(3),
        },
        marginRight: 0,
        backgroundColor: 'whitesmoke',
        borderRadius: 4,
        height: 40,
        alignSelf: 'center'
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: 'whitesmoke',
        // border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        // transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
        'Poppins',
        'sans-serif',
        ].join(','),
        '&:focus': {
        borderRadius: 4,
        // borderColor: '#80bdff',
        // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);


function Navbar(props) {

    const { t, } = useTranslation();
    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 991px)'});
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' });

    const [language, setLanguage] = React.useState(localStorage.getItem('dil') || 'tr');

    const handleChangeLanguage = (event) => {
        setLanguage(event.target.value);
        localStorage.setItem('dil', event.target.value);
        i18n.changeLanguage(event.target.value);
      };

    const dispatch = useDispatch();
    const { stepNumber, lastInfo } = useSelector(state => state.steps);
    const { detailedAdress, markerOn, roofArea, tabButonlarAktif } = useSelector(state => state.location);
    const { fiyat } = useSelector(state => state.summary);
    const { module } = useSelector(state => state.module);

    const handleChange = (newValue) => {
        dispatch(changeStep({stepNumber: newValue}));
      };

    return (
        <>
            {isTabletOrMobile && !lastInfo && <header>
                <div className={classes.headerWrapper}>
                    <div className={classes.headroom}>
                        <section className={classes.section}>
                            <div className={classes.innerWrapper}>
                                <div className={classes.navigationSection}>
                                    <div className={classes.seperateItems}>
                                        <a aria-label={module} href="https://integva.co" target='_blank' className={classes.hrefTag}>
                                            <img 
                                                src="/integva-logo1.png" 
                                                alt={module} 
                                                className={classes.logo} />
                                        </a>
                                        <nav className={classes.navv}>
                                            <button index={0} onClick={() => handleChange(0)} className={stepNumber === 0 ? classes.buttonTextUnderline : classes.buttonText}>1</button>
                                            <button index={1} onClick={() => handleChange(1)} className={stepNumber === 1 ? classes.buttonTextUnderline : classes.buttonText}>2</button>
                                            <button disabled={(fiyat === '-')} index={2} onClick={() => handleChange(2)} className={stepNumber === 2 ? classes.buttonTextUnderline : classes.buttonText}>3</button>
                                            <button disabled={(fiyat === '-')} index={3} onClick={() => handleChange(3)} className={stepNumber === 3 ? classes.buttonTextUnderline : classes.buttonText}>4</button>
                                        </nav>
                                        <StyledSelect
                                            labelId="Change-language"
                                            id="change-language"
                                            value={language}
                                            onChange={handleChangeLanguage}
                                            label="Language"
                                            variant="outlined"
                                            input={<BootstrapInputMobile />}
                                        >
                                            <MenuItem value="tr">
                                                <img
                                                    style={{ width: 20 }}
                                                    alt="Turkey"
                                                    src="/images/turkey.svg"
                                                />
                                            </MenuItem>
                                            <MenuItem value="en">
                                                <img
                                                    style={{ width: 20 }}
                                                    alt="United States"
                                                    src="/images/united-states.svg"
                                                />
                                            </MenuItem>
                                        </StyledSelect>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </header>}
            {isDesktopOrLaptop && <header className={classes.header}>
                <div className={classes.headerWrapper}>
                    <div className={classes.headroom}>
                        <section className={classes.section}>
                            <div className={classes.innerWrapper}>
                                <div className={classes.navigationSection}>
                                    <header className= {classes.NavigationHeader}>
                                        <a aria-label={module} href="https://integva.com" target='_blank' className={classes.hrefTag}>
                                            <img 
                                                src="/integva-logo1.png" 
                                                alt={module} 
                                                className={classes.logo} />
                                        </a>
                                        <div className={classes.languageProfileWrapper}>
                                            <StyledSelect
                                                labelId="Change-language"
                                                id="change-language"
                                                value={language}
                                                onChange={handleChangeLanguage}
                                                label="Language"
                                                variant="outlined"
                                                input={<BootstrapInput />}
                                            >
                                                <MenuItem value="tr">
                                                    <img
                                                        style={{ width: 20, marginRight: 10 }}
                                                        alt="Turkey"
                                                        src="/images/turkey.svg"
                                                    />
                                                    Türkçe
                                                </MenuItem>
                                                <MenuItem value="en">
                                                    <img
                                                        style={{ width: 20, marginRight: 10 }}
                                                        alt="United States"
                                                        src="/images/united-states.svg"
                                                    />
                                                    English
                                                </MenuItem>
                                            </StyledSelect>
                                        </div>
                                    </header>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </header>}
        </>
    );
}

export default Navbar;
