import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sistem: 'cobot',
  eksen: '6',
  eklem1: 100,
  eklem2: 100,
  eklem3: 50,
  eklem4: 50,
  eklem5: 50,
  eklem6: 50,
};

export const moduleSlice = createSlice({
  name: 'module',
  initialState,
  reducers: {
    changeSistem: (state, action) => {
      state.sistem = action.payload.sistem;
    },
    changeEksen: (state, action) => {
      state.eksen = action.payload.eksen;
    },
    changeEklem1: (state, action) => {
      state.eklem1 = action.payload.eklem1;
    },
    changeEklem2: (state, action) => {
      state.eklem2 = action.payload.eklem2;
    },
    changeEklem3: (state, action) => {
      state.eklem3 = action.payload.eklem3;
    },
    changeEklem4: (state, action) => {
      state.eklem4 = action.payload.eklem4;
    },
    changeEklem5: (state, action) => {
      state.eklem5 = action.payload.eklem5;
    },
    changeEklem6: (state, action) => {
      state.eklem6 = action.payload.eklem6;
    },
  }
})

// Action creators are generated for each case reducer function
export const {
  changeSistem,
  changeEksen,
  changeEklem1,
  changeEklem2,
  changeEklem3,
  changeEklem4,
  changeEklem5,
  changeEklem6,
} = moduleSlice.actions

export default moduleSlice.reducer