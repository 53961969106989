import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    priceCoint20: 0,
    priceCoint50: 0,
    priceCoint100: 0,
    priceAyak20: 0,
    priceAyak50: 0,
    priceAyak100: 0,
    priceBoru200: 0,
    priceBoru400: 0,
    priceBoru600: 0,
    priceTabanSacı1: 0,
    priceTabanSacı2: 0,
    priceDeltaKol1: 0,
    priceDeltaKol2: 0,
    priceDeltaPanel1: 0,
    priceDeltaPanel2: 0,
    priceTabla1: 0,
    priceTabla2: 0,
    priceKasa20: 0,
    priceKasa50: 0,
    priceKasa100: 0,
    priceScaraKol1: 0,
    priceScaraKol2: 0,
    pricePano1: 1,
    pricePano2: 1,
    pricePano3: 1,
};

export const priceSlice = createSlice({
  name: 'price',
  initialState,
  reducers: {
    changepriceCoint20: (state, action) => {
        state.priceCoint20 = action.payload.priceCoint20;
    },
    changepriceCoint50: (state, action) => {
        state.priceCoint50 = action.payload.priceCoint50;
    },
    changepriceCoint100: (state, action) => {
        state.priceCoint100 = action.payload.priceCoint100;
    },
    changepriceAyak20: (state, action) => {
        state.priceAyak20 = action.payload.priceAyak20;
    },
    changepriceAyak50: (state, action) => {
        state.priceAyak50 = action.payload.priceAyak50;
    },
    changepriceAyak100: (state, action) => {
        state.priceAyak100 = action.payload.priceAyak100;
    },
    changepriceBoru200: (state, action) => {
        state.priceBoru200 = action.payload.priceBoru200;
    },
    changepriceBoru400: (state, action) => {
        state.priceBoru400 = action.payload.priceBoru400;
    },
    changepriceBoru600: (state, action) => {
        state.priceBoru600 = action.payload.priceBoru600;
    },
    changepriceTabanSacı1: (state, action) => {
        state.priceTabanSacı1 = action.payload.priceTabanSacı1;
    },
    changepriceTabanSacı2: (state, action) => {
        state.priceTabanSacı2 = action.payload.priceTabanSacı2;
    },
    changepriceDeltaKol1: (state, action) => {
        state.priceDeltaKol1 = action.payload.priceDeltaKol1;
    },
    changepriceDeltaKol2: (state, action) => {
        state.priceDeltaKol2 = action.payload.priceDeltaKol2;
    },
    changepriceDeltaPanel1: (state, action) => {
        state.priceDeltaPanel1 = action.payload.priceDeltaPanel1;
    },
    changepriceDeltaPanel2: (state, action) => {
        state.priceDeltaPanel2 = action.payload.priceDeltaPanel2;
    },
    changepriceTabla1: (state, action) => {
        state.priceTabla1 = action.payload.priceTabla1;
    },
    changepriceTabla2: (state, action) => {
        state.priceTabla2 = action.payload.priceTabla2;
    },
    changepriceKasa20: (state, action) => {
        state.priceKasa20 = action.payload.priceKasa20;
    },
    changepriceKasa50: (state, action) => {
        state.priceKasa50 = action.payload.priceKasa50;
    },
    changepriceKasa100: (state, action) => {
        state.priceKasa100 = action.payload.priceKasa100;
    },
    changepriceScaraKol1: (state, action) => {
        state.priceScaraKol1 = action.payload.priceScaraKol1;
    },
    changepriceScaraKol2: (state, action) => {
        state.priceScaraKol2 = action.payload.priceScaraKol2;
    },
    changepricePano1: (state, action) => {
        state.pricePano1 = action.payload.pricePano1;
    },
    changepricePano2: (state, action) => {
        state.pricePano2 = action.payload.pricePano2;
    },
    changepricePano3: (state, action) => {
        state.pricePano3 = action.payload.pricePano3;
    },
    resetpriceList: () => initialState,
  }
})

// Action creators are generated for each case reducer function
export const {
    changepriceCoint20,
    changepriceCoint50,
    changepriceCoint100,
    changepriceAyak20,
    changepriceAyak50,
    changepriceAyak100,
    changepriceBoru200,
    changepriceBoru400,
    changepriceBoru600,
    changepriceTabanSacı1,
    changepriceTabanSacı2,
    changepriceDeltaKol1,
    changepriceDeltaKol2,
    changepriceDeltaPanel1,
    changepriceDeltaPanel2,
    changepriceTabla1,
    changepriceTabla2,
    changepriceKasa20,
    changepriceKasa50,
    changepriceKasa100,
    changepriceScaraKol1,
    changepriceScaraKol2,
    changepricePano1,
    changepricePano2,
    changepricePano3 
} = priceSlice.actions

export default priceSlice.reducer