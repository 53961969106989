import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isim: '',
  soyisim: '',
  telefon: '',
  mail: '',
  sirket: '',
  cookieShow: localStorage.getItem('cookieShow') || false,
};

export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    changeIsim: (state, action) => {
      state.isim = action.payload.isim;
    },
    changeSoyisim: (state, action) => {
      state.soyisim = action.payload.soyisim;
    },
    changeTelefon: (state, action) => {
      state.telefon = action.payload.telefon;
    },
    changeMail: (state, action) => {
      state.mail = action.payload.mail;
    },
    changeSirket: (state, action) => {
      state.sirket = action.payload.sirket;
    },
    changeCookieShow: (state, action) => {
      state.cookieShow = action.payload.cookieShow;
      localStorage.setItem('cookieShow', action.payload.cookieShow);
    },
    resetUser: () => initialState,
  }
})

// Action creators are generated for each case reducer function
export const {
  changeIsim,
  changeSoyisim,
  changeTelefon,
  changeMail,
  changeSirket,
  resetUser,
  changeCookieShow
} = userSlice.actions

export default userSlice.reducer